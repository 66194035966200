module.exports = {
  environment: {
    production: false,
    apiUrl: 'https://seul-proxy-espv2-ys53qirwqq-uc.a.run.app',
    platformUrl: 'https://platform.enviame.io',
    puconApiUrl: 'https://front.pucon.enviame.io/v1',
    estambulApiUrl: 'https://front.estambul.enviame.io/v1',
    madagascarApiUrl: 'https://madagascar.enviame.io/v1',
    santiagoApiUrl: 'https://api.enviame.io/api/s2/v2',
    torontoApiUrl: 'https://front.toronto.enviame.io/v1',
    recaptchaKey: '6Lf2vLUcAAAAAB2-9n2pw6xUILb9OkVQFrCD2EZX',
    beijingApiUrl: 'https://front.beijing.enviame.io/v1',
    nippurApiUrl: 'https://front.nippur.enviame.io/v1',
    pricingUrl: 'https://api-frontend-5k3oje3acq-uc.a.run.app/v1',
    dubaiApiUrl: 'https://front.dubai.enviame.io/v1',
    newyorkApiUrl: 'https://front.new-york.enviame.io',
    ottawaApiUrl: 'https://front.ottawa.enviame.io/v1',
    ottawaMeliRedirect: 'https://ottawa.enviame.io/v1',
    tokyoApiUrl: 'https://front.tokyo.enviame.io/api',
    tokyoStationApiUrl: 'https://front-station.tokyo.enviame.io/api',
    enviameApiUrl: 'https://api.enviame.io',
    shanghaiApiUrl: 'https://front.shanghai.enviame.io/v1',
    dementorsApiUrl: 'https://front.sydney.enviame.io/v1',
    apiKey: 'AIzaSyCTkGKJuQNl8lBUvR74hDsKaRmEjVdO1_U',
    authDomain: 'madagascar-ms.firebaseapp.com',
    projectId: 'madagascar-ms',
    storageBucket: 'madagascar-ms.appspot.com',
    messagingSenderId: '111561141785',
    appId: '1:111561141785:web:f8125060e66cf76a9fc488',
    newyorkDocsCloud: 'https://storage.cloud.google.com/new-york-billing-frontend-templates',
    sfApiUrl: 'https://san-francisco.enviame.io/v1',
    apiPlatform: 'https://api-app.enviame.io',
    apiKeyMap: 'AIzaSyDc73UYIuXkYxmb4lv00qrSmCvyl4RHMnY',
    entelApiUrl: 'https://front.entel.enviame.io/v1',
    dubaiDocsCloud: 'https://storage.cloud.google.com/dubai-ms.appspot.com',
    miamiApiUrl: 'https://front.miami.enviame.io',
    buenosairesApiUrl: 'https://front.buenos-aires.enviame.io',
    minatoApiUrl: 'https://front-minato.tokyo.enviame.io',
    bugsnagApiKey: 'db3d3c490535460ed383f19bdd15fab6'
  }
}
