<template>
  <div>
    <div class="d-flex justify-content-end animate-btn">
      <b-button v-if="(outSideAddButton && !showCheckboxes) || (outSideAddButton && showCheckboxes && mySelectedRows.length === 0)" variant="outline-light"  @click="newSchedules()" :disabled="disabled_new_schedules"><feather-icon icon="PlusIcon"/> {{$t('Nuevo Horario')}}</b-button>
    </div>  
    <div class="row mb-1 justify-content-end" v-for="(schedule, index) in formSchedules" :key="index">
      <div :class="outSideAddButton && hideDeleteBtn ? 'col-md-6' : 'col-md-5'" class="d-flex">
        <div class="d-flex align-items-center">
          <b-form-checkbox
            v-if="showCheckboxes"
              v-model="mySelectedRows"
              :id="`checkboxID${index}`"
              :name="`checkboxName${index}`"
              :value="schedule"
              class="pr-1"
              :disabled="disabledSchedulesForm"
          />
        </div>
        <div class="flex-fill">
          <label>{{daysLabel ? daysLabel: 'Día'}}<span class="text-danger" v-if="validation === 'schedule'"> *</span></label>
          <v-select 
            id="days"
            class="select-multiple w-100"
            multiple
            v-model="schedule.days"
            :name="`days_${name}_${index}`"
            :closeOnSelect="false"
            @input="changeDays(schedule, schedule.days, index + 1)"
            :dir="'ltr'"
            label="text"
            :options="daysOptions[index + 1]"
            :placeholder="placeholders.days"
            :selectable="(option) => !option.disabled" 
            :class="(typeof msg !== 'undefined' && formSchedules[index]['days'].length === 0 && validation === 'schedule') ? 'is-invalid' : ''"
            :disabled="disabledSchedulesForm"
          />
        </div>              
      </div>
      <div class="col-sm-10 col-md-6 d-flex" v-if="!type" :class="customizeFieldHours ? 'justify-content-end': ''">
        <div :class="customizeFieldHours ? 'fieldSchedule-hours-complete--ctn': 'fieldSchedule-hours--ctn'">
          <label>{{rangeLabelStart ? rangeLabelStart : 'Horario'}}<span class="text-danger" v-if="validation === 'schedule'"> *</span></label>
          <v-select
            v-model="schedule.open"
            :dir="'ltr'"
            :clearable="true"
            label="text"
            :options="hoursOptions.open"
            :name="`startHours_${name}_${index}`"
            placeholder="--:--"
            :class="(typeof msg !== 'undefined' && !formSchedules[index]['open']) ? 'is-invalid' : ''"
            @input="onChange"
            :disabled="disabledSchedulesForm"
          />
        </div>
        <div class="px-1 d-flex flex-wrap align-content-center">
          <span v-if="!rangeLabelClose" class="mt-1">a</span>
        </div>
        <div :class="customizeFieldHours ? 'fieldSchedule-hours-complete--ctn': 'fieldSchedule-hours--ctn'">
            <label><span>{{rangeLabelClose ? rangeLabelClose : ''}}<span class="text-danger" v-if="validation === 'schedule' && rangeLabelClose"> *</span></span></label>
            <v-select
              v-model="schedule.close"
              :dir="'ltr'"
              :clearable="true"
              label="text"
              :options="hoursOptions.close"
              :name="`endHours_${name}_${index}`"
              placeholder="--:--"
              @input="onChange"
              :class="(typeof msg !== 'undefined' && !formSchedules[index]['close']) ? 'is-invalid' : ''"
              :disabled="disabledSchedulesForm"
            />
        </div>
      </div>
      <div v-else class="col-md-6">
        <label><span>{{inputLabel? inputLabel : 'Horario'}}<span class="text-danger" v-if="validation === 'schedule'"> *</span></span></label>
        <b-form-input
          :placeholder="placeholders.hours"
          :name="`name_${index}`"
          v-model="schedule.hours"
          @input="onChange"
          @blur="maskRangeHours(index)"
          :state="(
            typeof msg !== 'undefined' && !formSchedules[index].hours && validation !== 'checkMinLength' ||
            (typeof msg !== 'undefined' && (badTimeInput[index] && validation !== 'checkMinLength' && formSchedules[index].hours)) ||
            typeof msg !== 'undefined' && validation === 'checkMinLength' && formSchedules[index].hours && badTimeInput[index]
          ) ? false : undefined"
          aria-describedby="input-live-help"
        />
      </div>
      <div class="col-sm-2 col-md-1 d-flex align-items-center">
        <b-button v-if="!outSideAddButton && !index" variant="outline-light" @click="newSchedules()" :disabled="disabled_new_schedules" class="add-schedule-btn"><feather-icon icon="PlusIcon" size="22"/></b-button>
        <b-button v-else-if="!hideDeleteBtn && index" variant="outline-danger" @click="deleteSchedules(index)" class="delete-schedule-btn"><feather-icon icon="TrashIcon" size="16"/></b-button>
      </div>
    </div>
    
    <b-form-invalid-feedback 
      class="mt-0 mb-1" :state="(typeof msg === 'undefined') ? undefined : false"
      v-if="
        (msg && msg.text && disabled_new_schedules && validation !== 'checkMinLength') ||
        (msg && msg.text && badTimeInput.includes(true))
    ">
      {{msg.text}}
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name:'field-schedule',
  props: ['value', 'placeholder', 'msg', 'change', 'name', 'hoursOptions', 'showCheckboxes', 'selectedRows', 'outSideAddButton', 'inputLabel', 'rangeLabelStart', 'rangeLabelClose', 'hideDeleteBtn', 'formatHours', 'limitHours', 'validation', 'selectAllByDefault', 'type', 'daysLabel', 'customizeFieldHours', 'disabledSchedulesForm'],
  components: { vSelect },
  data () {
    return {
      mySelectedRows: [],
      limitHourValidation: '24',
      formSchedules: {},
      placeholders: {
        days: 'Seleccione días',
        hours: 'Ingrese horario'
      },
      showErrorOnNotRequiredInput: false,
      daysOptions: {
        1: [
          { id: '*', text: 'Todos los días', days: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'], disabled: false},
          { id: 'LU', text: 'Lunes', disabled: false},
          { id: 'MA', text: 'Martes', disabled: false },
          { id: 'MI', text: 'Miércoles', disabled: false },
          { id: 'JU', text: 'Jueves', disabled: false },
          { id: 'VI', text: 'Viernes', disabled: false },
          { id: 'SÁ', text: 'Sábado', disabled: false },
          { id: 'DO', text: 'Domingo', disabled: false }
        ]
      },
      disabled_new_schedules: false,
      badTimeInput: []
    }
  },
  watch: {
    selectedRows () {
      this.mySelectedRows = this.selectedRows
      this.selectedRows.length > 0 ? this.$animateCSS('.animate-btn', 'fadeOut', 'faster') : this.$animateCSS('.animate-btn', 'fadeIn', 'faster')
    },
    mySelectedRows () {
      this.$emit('update:selectedRows', this.mySelectedRows)
    },
    formSchedules: {
      handler (val, oldVal) {
        // Deshabilitar boton de agregar horarios si el ultimo horario esta incompleto
        const lastForm = val.slice(-1)
        let completeSchedule = null
        if (!this.type) {
          completeSchedule = lastForm.filter(item => item.close && item.open && item.days.length > 0)
        }
        else {
          completeSchedule = lastForm.filter(item => item.hours && item.days.length > 0)
        }
        this.disabled_new_schedules = completeSchedule.length === 0
        if (this.disabledSchedulesForm) this.disabledForm()
      },
      deep: true
    },
    value() {
      this.setSchedules()
    }
  },
  mounted () {
    this.mySelectedRows = this.selectedRows    
    this.setSchedules()
    if (this.limitHours) this.limitHourValidation = this.limitHours
    if (this.placeholder?.days) this.placeholders.days = this.placeholder.days
    this.placeholders.hours = this.placeholder?.hours ? this.placeholder.hours : (`${this.limitHourValidation}00${this.limitHourValidation}00`).replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, this.formatHours ? this.formatHours : '$1:$2 - $3:$4')
    if (this.disabledSchedulesForm) this.disabledForm()
  },
  methods: {
    setSchedules () {
      this.formSchedules = this.value ? this.value : []
      if (this.formSchedules.length === 0) this.newSchedules()
      else this.createDaysOptions(this.formSchedules)
    },
    maskRangeHours (index) {
      if (!this.formSchedules[index].hours) {
        if (this.validation === 'checkMinLength') this.showErrorOnNotRequired()
        return
      }
      const cleanVal = this.formSchedules[index].hours.replace(/\D/g, '').substring(0, 8)
      const startHours = (Number(cleanVal.substring(0, 2)) > Number(this.limitHourValidation) ? this.limitHourValidation : cleanVal.substring(0, 2)) + (Number(cleanVal.substring(2, 4)) > 59 ? '59' : cleanVal.substring(2, 4))
      const startClose = (Number(cleanVal.substring(4, 6)) > Number(this.limitHourValidation) ? this.limitHourValidation : cleanVal.substring(4, 6)) + (Number(cleanVal.substring(6, 8)) > 59 ? '59' : cleanVal.substring(6, 8))
      const formatValue = startHours + startClose
      this.formSchedules[index].hours = formatValue.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, this.formatHours ? this.formatHours : '$1:$2 - $3:$4')
      this.badTimeInput = this.formSchedules.map(({hours}) => hours?.length < 8)
    },
    onChange () {
      this.$debounce(name, () => {
        this.$emit('update:value', this.formSchedules)
        if (this.change) this.change(this.name, this.formSchedules)
      }, (this.changeDebounce || 0))      
    },
    changeDays (schedule, value, index) {
      if (value && value.length > 0 && value.findIndex(el => el.id === '*') !== -1) {
        this.formatterOptions('disabled', index)
        schedule.days = [this.daysOptions[1][0]]
      } else {
        this.formatterOptions('enabled', index)
      }
    },
    newSchedules () {
      let addNewSchedule = {
        days: this.selectAllByDefault ? [this.daysOptions[1][0]] : []
      }
      if (!this.type) {
        addNewSchedule = {
          ...addNewSchedule,
          close: null,
          open: null
        }
      } else {
        addNewSchedule = {
          ...addNewSchedule,
          hours: null
        }
      }
      this.formSchedules.push(addNewSchedule)
      this.createDaysOptions(this.formSchedules, true)
    },
    createDaysOptions (schedule, disabledOption = false) {
      const options = this.daysOptions[1]
      const formatterOptionsDays = options.map(item => {
        if (item.id !== '*') return {...item, disabled: !!this.selectAllByDefault}
        else return item
      })
      if (!disabledOption) {
        schedule.map((item, index) => {
          if (item.days[0]?.id === '*') this.daysOptions[index + 1] = formatterOptionsDays
          else this.daysOptions[index + 1] = options
        })
      } 
      else {
        const index = schedule.length
        this.daysOptions[index] = formatterOptionsDays
      }  
    },
    formatterOptions (type, index) {
      const disabledOptions = this.daysOptions[index].map(el => {
        if (el.text !== 'Todos los días') return {...el, disabled: type === 'disabled'}
        else return el
      })
      this.daysOptions[index] = disabledOptions
      if (this.change) this.change(this.name, this.formSchedules)
    },
    deleteSchedules(index) {
      this.formSchedules = this.formSchedules.splice(index, 1)
    },
    showErrorOnNotRequired () {
      if (this.formSchedules.length === 1) {
        this.badTimeInput = [false]
      }
      else {
        this.badTimeInput = []
        this.formSchedules.forEach(val => {
          const hours = val.hours.replace(/\D/g, '').substring(0, 8)
          this.badTimeInput.push(hours.length < 8 && hours.length > 1)
        })
      }
    },
    disabledForm () {
      this.disabled_new_schedules = true
    }
  }
}
</script>

<style lang="scss">
#days .vs__dropdown-option--disabled:hover {
cursor: not-allowed;
}
.fieldSchedule-hours--ctn{
  min-width: 45% !important;
}
.fieldSchedule-hours-complete--ctn {
  min-width: 48% !important;
}
.add-schedule-btn, .delete-schedule-btn{
  margin-top: 10px !important; 
}
.add-schedule-btn {
  padding: 5px !important;
}
.delete-schedule-btn{
  padding: 8px !important;
}
</style>