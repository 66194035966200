<template>
  <b-card
    no-body
    :class="{'open': visible}"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="directionArrows"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="toggleVisibility"
    >
      <slot name="header">
        <span class="lead collapse-title">{{ title }}</span>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      :visible="visible"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    customArrowDirection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
    directionArrows () {
      if (!!this.customArrowDirection) return !this.visible ? 'true' : 'false'
      else return this.visible ? 'true' : 'false'
    }
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    toggleVisibility() {
      this.visible = !this.visible
      if (this.autoClose && this.$parent.accordion) {
        this.$parent.$children.forEach(item => {
          if (item !== this && item.autoClose) {
            item.visible = false
          }
        })
      }
    },
    openCollapseManually () {
      this.visible = true
    },
    collapseOpen() {
      if (this.openOnHover) this.visible = true
    },
    collapseClose() {
      if (this.openOnHover) this.visible = false
    }
  }
}
</script>
