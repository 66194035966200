
import Vue from 'vue'
import alertToast from '@/views/components/custom/alert-toast-render/alertToast.vue'



function handlerStandartError(error, noTag = true) {

  const isStandardErr = Array.isArray(error?.errors) && error?.errors[0]?.detail
 
  const subtitle = isStandardErr ? `${error?.errors[0]?.title}${noTag ? '' : '<br>'}` : ''
  const detail = isStandardErr ? error.errors.map((e) => (Array.isArray(e.detail) ? e.detail.join('.<br>') : e.detail)).join('.<br>') : error.message
 
  return { subtitle, detail }
}

function showAlert({subtitle, detail}, showToast) {

  const vm = new Vue({
    render: h => h(alertToast)
  }).$mount()

  vm.$children[0].showError(subtitle, detail, 'danger', showToast)
}

export function handleBlobError(err, showToast = false) { // ---- Fn principal ----
  
  if (!(err instanceof Blob)) {
    showAlert(handlerStandartError(err), showToast)
    return
  }

  const reader = new FileReader()
  reader.onloadend = () => {
    try {
      const jsonData = JSON.parse(reader.result)
      const errorDetail = handlerStandartError(jsonData)
      showAlert(errorDetail, showToast)
    } catch (jsonError) {
      const errorDetail = handlerStandartError(jsonError)
      showAlert(errorDetail, showToast)
    }
  }
  reader.readAsText(err)      
}