import { environment } from '@/environments/environment'

export default [
  { name: 'getManifestList', url: `${environment.estambulApiUrl}/summaries`, method: 'get' },
  // { name: 'getCompaniesManifest', url: `${environment.puconApiUrl}/shippers`, method: 'get' },
  // GET shipers for filters
  { name: 'getShippers', url: `${environment.dubaiApiUrl}/shippers`, method: 'get'},
  { name: 'getShippersByOrganizationSimplifiedQueryParams', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers?simplified=true`, method: 'get' },
  { name: 'getCarriersManifest', url: `${environment.puconApiUrl}/carriers`, method: 'get' },
  { name: 'getCarriersNY', url: `${environment.newyorkApiUrl}/v2/coverage/carriers`, method: 'get' },
  { name: 'getDeliveriesManifest', url: `${environment.beijingApiUrl}/deliveries`, method: 'get' },
  { name: 'getAddDeliverySummaries', url: `${environment.apiPlatform}/v1/summaries/{idSummary}/find-new-delivery`, method: 'get'},
  { name: 'postCloseSummaries', url: `${environment.apiPlatform}/v1/summaries/{idSummary}/close`, method: 'post'},
  { name: 'closeSummariesAsAdmin', url: `${environment.apiPlatform}/v1/summaries/{summary_id}/close-as-admin`, method: 'post'},
  { name: 'postKeepSummaries', url: `${environment.apiPlatform}/v1/summaries/{idSummary}/update`, method: 'post'},
  { name: 'postReopenSummaries', url: `${environment.apiPlatform}/v1/summaries/{idSummary}/toggle-active`, method: 'post'},
  { name: 'getExportDeliveries', url: `${environment.apiPlatform}/v1/summaries/{idSummary}/export-deliveries`, method: 'get'},
  { name: 'getSummaryLogs', url: `${environment.apiPlatform}/v1/summaries/{summary_id}/logs`, method: 'get'},
  { name: 'getManifestListReturn', url: `${environment.estambulApiUrl}/return-summaries`, method: 'get'},
  { name: 'postCloseSummariesReturn', url: `${environment.estambulApiUrl}/return-summaries/{idSummary}/close`, method: 'put'}
]