

function cleanElement(objectUrl, anchorElement) {
  window.URL.revokeObjectURL(objectUrl)
  anchorElement.removeEventListener('load', cleanElement)
  document.body.removeChild(anchorElement)
}

export function downloadExcel(data, name) { // ---- Fn pricipal ----

  const anchorElement = document.createElement('a')
  const objectUrl = window.URL.createObjectURL(data)
  anchorElement.href = objectUrl
  anchorElement.download = `${name || 'Descarga'}.xlsx`

  document.body.appendChild(anchorElement)
  anchorElement.click()
  // Esta FN limpia recursos después de la descarga para evitar problemas de memoria
  cleanElement(objectUrl, anchorElement)
}


export const downloadInvoiceByType = (data, contentType, filename = 'Factura') => {
  const blob = new Blob([data], { type: contentType })
  if (contentType === 'application/pdf') {
    // Para PDF, muestra en una nueva pestaña
    const newTab = window.open('', '_blank')
    newTab.location = URL.createObjectURL(blob)
  } else if (contentType === 'application/xml') {
    // Para XML, descarga el archivo
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.download = filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
    URL.revokeObjectURL(downloadLink.href)
  }
}
