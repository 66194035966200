import { environment } from '@/environments/environment'
export default [
  {
    name: 'getCountriesOrigins',
    url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries`,
    method: 'get',
    notCancel: true
  },
  {
    name: 'getCountriesOriginsModal',
    url: `${environment.newyorkApiUrl}/v1/coverage/countries`,
    method: 'get',
    notCancel: true
  },
  {
    name: 'getRatesOrigins',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates`,
    method: 'get'
  },
  {
    name: 'getServicesModalOrigins',
    url: `${environment.newyorkApiUrl}/v2/coverage/carriers/{carrier_id}/services`,
    method: 'get'
  },
  {
    name: 'getCarriersOrigins',
    url: `${environment.newyorkApiUrl}/v1/coverage/countries/{country_id}/carriers`,
    method: 'get'
  },
  {
    name: 'getServicesOrigins',
    url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services`,
    method: 'get'
  },
  {
    name: 'postCoverage',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/import-future-coverages`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'postInternationalCoverage',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/import-international-coverages`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'postCoverageKm',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/import-future-coverages-km`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'postFreightCharges',
    url: `${environment.newyorkApiUrl}/v2/coverage/carriers/{carrier_id}/services/{service_id}/import-freight-charges`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'postBigCarriers',
    url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/import-big-coverages`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'putBigCarriers',
    url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/update-big-coverages`,
    method: 'put'
  },
  {
    name: 'putUpdateCoveragekm',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/update-coverages-km`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'postCoveragePreferential',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/company/{company_id}/import-preferential-coverage`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'postBigCarriersPreferential',
    url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/company/{company_id}/import-preferential-big-coverages`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'putUpdateFreightCharges',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/update-freight-charges`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'putUpdateAdditionalInfo',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/upsert-additional-carrier-information`,
    method: 'put',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'putReplicateCoverages',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/origins/{origin_id}/replicate-coverages`,
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  {
    name: 'deleteRate',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}`,
    method: 'delete',
    notCancel: true
  },
  {
    name: 'deleteInternationalRate',
    url: `${environment.newyorkApiUrl}/v3/coverage/international-rates/{rate_id}`,
    method: 'delete',
    notCancel: true
  },
  { name: 'putBigPreferentialCoverages', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/company/{company_id}/rates/{rate_id}/update-big-preferential-coverages`, method: 'put', headers: {
    'Content-Type': 'multipart/form-data'
  }},
  { name: 'putPreferentialCoverage', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/company/{company_id}/update-preferential-coverage`, method: 'put', headers: {
    'Content-Type': 'multipart/form-data'
  }},
  {
    name: 'getInternationalRates',
    url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/international-coverages`,
    method: 'get'
  }
]
