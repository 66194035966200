<template>
  <div>
    <b-form-spinbutton
      v-model="myValue"
      :id="id"
      :min="specsRange.min"
      :max="specsRange.max"
      :formatter-fn="formatter"
      :placeholder="placeholder"
      :state="(typeof msg === 'undefined') ? undefined : false"
      :step="step"
      :vertical="vertical"
      class="field-spinbutton"
      :class="classField"
      @input="onChange"
    />
    <b-form-invalid-feedback v-if="msg && msg.text">
      {{msg.text}}
    </b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  props: ['id', 'value', 'change', 'placeholder', 'msg', 'disabled', 'name', 'specsRange', 'formatter', 'step', 'vertical', 'classField'],
  name: 'field-spinbutton',
  data() {
    return {
      myValue: undefined
    }
  },
  watch: {
    value () {
      this.myValue = this.value || this.specsRange.min
    }
  },
  mounted () {
    this.myValue = this.value || this.specsRange.min
  },
  methods: {
    onChange (e) {
      this.$emit('update:value', this.myValue)
      if (this.change) this.change(this.name, this.myValue)
    }
  }
}
</script>


<style lang="scss">
.field-spinbutton button{
  background-color: #004389 !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}
.field-spinbutton button,
.field-spinbutton button:hover{
  color: white;
}
.field-spinbutton button:hover{
  background-color: #004fa4 !important;
}
</style>
