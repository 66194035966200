import Vue from 'vue'
const moment = require('moment')
require('moment/locale/es')
import { DateTime } from 'luxon'
 
Vue.use(require('vue-moment'), {
  moment
})

Vue.filter('dbDateToFormat', (value, format) => {
  // (Examples below given for 2014-08-06T13:07:04.054 considered as a local time in America/New_York).
  //  ____________________________________________________________________________________________________________________________________________
  // | S	    |	millisecond, no padding                                           |	54                                                            |
  // | SSS  	|	millisecond, padded to 3                                          | 054                                                           |
  // | u	    |	fractional seconds, functionally identical to SSS                 |	054                                                           |
  // | s	    |	second, no padding                                                |	4                                                             |
  // | ss	    |	second, padded to 2 padding                                       |	04                                                            |
  // | m	    |	minute, no padding                                                |	7                                                             |
  // | mm	    |	minute, padded to 2                                               |	07                                                            |
  // | h	    |	hour in 12-hour time, no padding                                  |	1                                                             |
  // | hh	    |	hour in 12-hour time, padded to 2                                 |	01                                                            |
  // | H	    |	hour in 24-hour time, no padding                                  |	9                                                             |
  // | HH	    |	hour in 24-hour time, padded to 2                                 |	13                                                            |
  // | Z	    |	narrow offset                                                     |	+5                                                            |
  // | ZZ	    |	short offset                                                      |	+05:00                                                        |
  // | ZZZ	  |	techie offset                                                     |	+0500                                                         |
  // | ZZZZ	  |	abbreviated named offset                                          |	EST                                                           |
  // | ZZZZZ	|	unabbreviated named offset                                        |	Eastern Standard Time                                         |
  // | z	    |	IANA zone                                                         |	America/New_York                                              |
  // | a	    |	meridiem                                                          |	AM                                                            |
  // | d	    |	day of the month, no padding                                      |	6                                                             |
  // | dd	    |	day of the month, padded to 2                                     |	06                                                            |
  // | c      |	E	day of the week, as number from 1-7 (Monday is 1, Sunday is 7)  |	3                                                             |
  // | ccc    |	EEE	day of the week, as an abbreviate localized string            |	Wed                                                           |
  // | cccc   |	EEEE	day of the week, as an unabbreviated localized string       |	Wednesday                                                     |
  // | ccccc  |	EEEEE	day of the week, as a single localized letter               |	W                                                             |
  // | L      |	M	month as an unpadded number                                     |	8                                                             |
  // | LL     |	MM	month as an padded number                                     |	08                                                            |
  // | LLL    |	MMM	month as an abbreviated localized string                      |	Aug                                                           |
  // | LLLL   |	MMMM	month as an unabbreviated localized string                  |	August                                                        |
  // | LLLLL  |	MMMMM	month as a single localized letter                          |	A                                                             |
  // | y	    |	year, unpadded                                                    |	2014                                                          |
  // | yy	    |	two-digit year                                                    |	14                                                            |
  // | yyyy	  |	four- to six- digit year, pads to 4                               |	2014                                                          |
  // | G	    |	abbreviated localized era                                         |	AD                                                            |
  // | GG	    |	unabbreviated localized era                                       |	Anno Domini                                                   |
  // | GGGGG	|	one-letter localized era                                          |	A                                                             |
  // | kk	    |	ISO week year, unpadded                                           |	14                                                            |
  // | kkkk	  |	ISO week year, padded to 4                                        |	2014                                                          |
  // | W	    |	ISO week number, unpadded                                         |	32                                                            |
  // | WW	    |	ISO week number, padded to 2                                      |	32                                                            |
  // | o	    |	ordinal (day of year), unpadded                                   |	218                                                           |
  // | ooo	  |	ordinal (day of year), padded to 3                                |	218                                                           |
  // | q	    |	quarter, no padding                                               |	3                                                             |
  // | qq	    |	quarter, padded to 2                                              |	03                                                            |
  // | D	    |	localized numeric date                                            |	9/4/2017                                                      |
  // | DD	    |	localized date with abbreviated month                             |	Aug 6, 2014                                                   |
  // | DDD	  |	localized date with full month                                    |	August 6, 2014                                                |
  // | DDDD	  |	localized date with full month and weekday                        |	Wednesday, August 6, 2014                                     |
  // | t	    |	localized time                                                    |	9:07 AM                                                       |
  // | tt	    |	localized time with seconds                                       |	1:07:04 PM                                                    |
  // | ttt	  |	localized time with seconds and abbreviated offset                |	1:07:04 PM EDT                                                |
  // | tttt	  |	localized time with seconds and full offset                       |	1:07:04 PM Eastern Daylight Time                              |
  // | T	    |	localized 24-hour time                                            |	13:07                                                         |
  // | TT	    |	localized 24-hour time with seconds                               |	13:07:04                                                      |
  // | TTT	  |	localized 24-hour time with seconds and abbreviated offset        |	13:07:04 EDT                                                  |
  // | TTTT	  |	localized 24-hour time with seconds and full offset               |	13:07:04 Eastern Daylight Time                                |
  // | f	    |	short localized date and time                                     |	8/6/2014, 1:07 PM                                             |
  // | ff	    |	less short localized date and time                                |	Aug 6, 2014, 1:07 PM                                          |
  // | fff	  |	verbose localized date and time                                   |	August 6, 2014, 1:07 PM EDT                                   |
  // | ffff	  |	extra verbose localized date and time                             |	Wednesday, August 6, 2014, 1:07 PM Eastern Daylight Time      |
  // | F	    |	short localized date and time with seconds                        |	8/6/2014, 1:07:04 PM                                          |
  // | FF	    |	less short localized date and time with seconds                   |	Aug 6, 2014, 1:07:04 PM                                       |
  // | FFF	  |	verbose localized date and time with seconds                      |	August 6, 2014, 1:07:04 PM EDT                                |
  // | FFFF	  |	extra verbose localized date and time with seconds                |	Wednesday, August 6, 2014, 1:07:04 PM Eastern Daylight Time   |
  // | X  	  |	unix timestamp in seconds                                         |	1407287224                                                    |
  // | x	    |	unix timestamp in milliseconds                                    |	1407287224054                                                 |
  // |____________________________________________________________________________________________________________________________________________|
  return DateTime.fromISO(value, { setZone: true }).toFormat(format)
})

Vue.filter('jsDateToFormat', (value, format) => {
  return DateTime.fromJSDate(value, { setZone: true }).toFormat(format)
})

Vue.filter('dbDateToJs', (value, format) => {
  
})

Vue.filter('dbDateUTCToGMTlocal', (value, format = 'DD/MM/YYYY HH:mm') => {
  const date = new Date(value)
  return moment(date).format(format)
})

Vue.filter('dateToFullDate', (date) => {
  if (!date) return null
  const [year, month, day] = date.split('-')
  const newDate = `${month}-${day}-${year}`
  return new Date(newDate)
})

Vue.filter('UTCDateFormatter', (date, format = '-') => {
  const newDate = new Date(date)
  let day = newDate.getUTCDate()
  let month = newDate.getUTCMonth() + 1
  day = day < 10 ? `0${day}` : day
  month = month < 10 ? `0${month}` : month
  return `${day}${format}${month}${format}${newDate.getUTCFullYear()}`
})

Vue.filter('title', (value, replacer = '_') => {
  if (!value) return ''
  value = value.toString()
  const arr = value.split(replacer)
  const capitalized_array = []
  arr.map((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalized_array.push(capitalized)
  })
  return capitalized_array.join(' ')
})

Vue.filter('currency', (value, decimals = 0, prefix = '', suffix = '', conditionalDecimals = false) => {
  //Filtro usado para visualizar información de moneda. Para elementos input, debe usarse currencyMask del archivo masks.js
  if (!value && ![0, '0'].includes(value)) return ''
  const decFixed = (value / 1).toFixed(decimals)
  const val = (conditionalDecimals && decFixed.split('.')[1] === '00') ? decFixed.split('.')[0]  : decFixed.replace('.', ',')
  return `${prefix}${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}${suffix}`
})
Vue.filter('currencySymbol', (country) => {
  //Filtro usado para obtener el simbolo de la moneda de cada país!.
  const currencySimbol = {
    cl: '$',
    co: '$',
    pe: 'S/',
    mx: '$'
  }
  return currencySimbol[country.toLowerCase()]
})

Vue.filter('monthName', (date) => {
  const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
  const dateParse = new Date(date)
  return monthNames[dateParse.getMonth()]
})

Vue.filter('formatByte', (bytes, decimals = 2) => {
  if (bytes === 0) return '0 B'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals) * 1} ${sizes[i]}`
})

Vue.filter('formatRut', (rut) => {
  //Filtro para formatear el rut
  const value = rut.replace(/[^A-Za-z0-9]+/g, '')
  return `${value.slice(0, -1)}-${value.slice(-1).toUpperCase()}`
})

Vue.filter('momentSubtract', (days = 0, time = 'days') => {
  return moment().subtract(days, time).toDate()
})

Vue.filter('momentAdd', (days = 0, time = 'days') => {
  return moment().add(days, time).toDate()
})

Vue.filter('countryLevels', () => {
  return Object.freeze({ //Esto lo tome literalmente de un switch case en platform
    1: null,
    2: 5,
    3: 16,
    4: 10,
    5: 20,
    6: 23
  })
})


Vue.filter('getStatusClasses', () => {
  //variables con los nombres de las clases para escribirlos una sola vez
  const classes = Object.freeze({
    default: 'table_dot--state-creado',
    blue: {
      lightest: 'table_dot--state-progress',
      lighter: 'table_dot--state-progress2',
      light: 'table_dot--state-progress3',
      dark:'table_dot--state-progress4',
      darker:'table_dot--state-progress5',
      darkest:'table_dot--state-progress6'
    },
    red: 'table_dot--state-incidence',
    green: 'table_dot--state-ok',
    yellow: {
      lightest: 'table_dot--state-warning',
      light: 'table_dot--state-warning2',
      dark:'table_dot--state-warning3',
      darkest:'table_dot--state-warning4'
    }
  })
  //Retorna el objeto con los id y sus clases respectivas
  return {
    /****
     * Estatus en DEFAULT - GRIS
     ****/
    1: classes.default, //Creado
    /****
     * Estatus en PROGRESO - AZUL
     ****/
    5: classes.blue.lightest, //Listo para despacho
    62:classes.blue.lightest, //Listo para despacho
    26:classes.blue.lighter, //Listo para despacho - Impreso
    63:classes.blue.lighter, //Listo para despacho - Impreso        
    3: classes.blue.light, //Enviado a Carrier
    57:classes.blue.light, //Entregado a Carrier
    66:classes.blue.light, //Para entregar a fulfillment
    67:classes.blue.light, //Entregado a fulfillment
    16:classes.blue.light, //Retirado por carrier        
    75:classes.blue.dark, //Manifiesto confirmado        
    6: classes.blue.darker, //En planta de origen
    7: classes.blue.darker, //En tránsito
    8: classes.blue.darker, //En planta de destino        
    2: classes.blue.darker, //Procesando
    36:classes.blue.darker, //Gestión en proceso        
    47:classes.blue.darkest, //Asignado
    68:classes.blue.darkest, //Asignado a repartidor
    9: classes.blue.darkest, //En Reparto        
    34:classes.blue.darkest, //Solucionado y enviando a destinatario    
    45:classes.blue.darkest, //Admitido en Sucursal/Agencia
    65:classes.blue.darkest, //Paquete listo para ser retirado
    11:classes.blue.darkest, //Disponible para retiro
    50:classes.blue.darkest, //Entrega parcial
    /****
     * Estatus en INCIDENCIAS - ROJO
     ****/
    4: classes.red, //Rechazado por Carrier
    12:classes.red, //Eliminado
    17:classes.red, //Dirección no encontrada
    18:classes.red, //Rechazado por destinatario
    19:classes.red, //No hay quien reciba
    20:classes.red, //Envío dañado
    21:classes.red, //Envío extraviado
    22:classes.red, //Envío extraviado
    23:classes.red, //Pendiente
    24:classes.red, //Extraviado
    25:classes.red, //Transporte sin acceso
    27:classes.red, //Envío no pudo ser entregado
    29:classes.red, //Expirado
    32:classes.red, //Devolucion no retirada por carrier
    35:classes.red, //Destinatario desconocido
    37:classes.red, //Pendiente - requiere solución
    38:classes.red, //Presuntamente Extraviado
    39:classes.red, //Destinatario cambió de dirección
    40:classes.red, //Envío retenido
    41:classes.red, //Entrega fallida
    43:classes.red, //Devolución cancelada
    46:classes.red, //Dirección incorrecta
    48:classes.red, //Extraviado - Sin movimiento
    49:classes.red, //Anulado
    51:classes.red, //Incumplimiento de servicio
    52:classes.red, //Fuera de cobertura
    53:classes.red, //No retirado - Incidencia seller
    54:classes.red, //Pronto a Expirar
    56:classes.red, //Expirado en tienda
    64:classes.red, //Retraso por fuerza mayor
    76:classes.red, //Destinatario se rehusa a pagar
    /****
     * Estatus en OK - VERDE
     ****/
    10:classes.green, //Entregado
    13:classes.green, //Retirado por cliente
    /****
     * Estatus en WARNING - AMARILLO
     ****/
    14:classes.yellow.lightest, //Devolucion
    70:classes.yellow.lightest, //Devolución parcial
    71:classes.yellow.lightest, //Devolución parcial
    55:classes.yellow.light, //Iniciar devolución
    44:classes.yellow.light, //Devolución a remitente
    31:classes.yellow.dark, //Devolucion retirada por carrier
    33:classes.yellow.dark, //Devolucion en reparto
    30:classes.yellow.darkest //Devolucion entregada     
  }
})

Vue.filter('humanizeExpirationDate', (date, format) => {
  if (!date) return null
  const today = new Date()
  const targetDate = new Date(date)
  const oneYear = 31536000000
  const oneMonth = 2628000000
  const oneDay = 86400000

  let difference = targetDate - today

  if (difference < 0) {
    return 'La fecha ya ha pasado.'
  } else if (difference === 0) {
    return 'Es hoy!'
  }

  const years = Math.floor(difference / oneYear)
  difference -= years * oneYear
  const months = Math.floor(difference / oneMonth)
  difference -= months * oneMonth
  const days = Math.floor(difference / oneDay)

  const timeUnits = [
    { quantity: years, singularTime: 'año', pluralTime: 'años' },
    { quantity: months, singularTime: 'mes', pluralTime: 'meses' },
    { quantity: days, singularTime: 'día', pluralTime: 'días' }
  ]
  const formattedDate = timeUnits.filter(time => time.quantity > 0).map(time => `${time.quantity} ${time.quantity > 1 ? time.pluralTime : time.singularTime}`).join(' ')
  
  return formattedDate.trim() || null
})