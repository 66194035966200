<template>
  <div :class="[!!icon || !!append || clearable || type === 'password' ? 'container--icon-right': '', !!prepend || !!iconLeft ? 'container--icon-left' : '']" >
    <component :is="optionGroup ? 'b-input-group' : 'b-form-group'"
      v-bind="optionGroup"
    >
      <div>
        <b-input-group-prepend is-text v-if="!!prepend" :class="[disabled ? 'disabled-prepend ': '', 'icon--left--input prepend-text']">
          <span :style=" {color: prepend.color || '#6e6b7b'}">{{ prepend.text }}</span>
        </b-input-group-prepend>
        <b-input-group-prepend is-text v-if="!!iconLeft" class="icon--left--input prepend-text">
          <feather-icon :icon="iconLeft.icon" :size="iconLeft.size || '18'" :stroke-width="iconLeft.stroke || '2'" :color="iconLeft.color || '#6e6b7b'"/>
        </b-input-group-prepend>
        <b-form-input
          :placeholder="placeholder"
          :type="myType || 'text'"
          :class="[(!!action? 'with-action' : ''), (noZIndex ? '' : 'zindex'), (disabled ? inputDisabledClass : '')]"
          :name="name"
          :disabled="disabled"
          v-model="myValue"
          @input="onChange"
          @blur="onBlur"
          :options="options"
          :min="min"
          :max="max"
          :formatter="!!max ? ($event) => formatterMax($event, max) : null"
          :state="(typeof msg === 'undefined' && !msgError ) ? undefined : false"
          :step="myType === 'number' && enableStep ? '.0000001': ''"
          aria-describedby="input-live-help"
        />
        <b-input-group-append is-text v-if="clearable && myValue"  class="clearable" v-b-tooltip.hover title= "Eliminar">
          <feather-icon icon="XIcon" class="cursor-pointer" size="14" @click="clean" />
        </b-input-group-append>
        <b-input-group-append is-text v-if="type === 'password'" class="icon--right--input append-text">
          <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePassword" />
        </b-input-group-append>
        <b-input-group-append is-text v-if="!!icon" class="icon--right--input append-text">
          <feather-icon :icon="icon" />
        </b-input-group-append>
        <b-input-group-append is-text v-if="!!append" :class="[disabled ? 'disabled-append ': '', 'icon--right--input append-text']">
          <span :style=" {color: append.color || '#6e6b7b'}">{{ append.text }}</span>
        </b-input-group-append>
        <b-form-invalid-feedback v-if="msgError">
          {{msgError}}
        </b-form-invalid-feedback>
      </div>
      <b-form-invalid-feedback v-if="msg && msg.text">
        {{msg.text}}
      </b-form-invalid-feedback>
      <b-form-text id="input-live-help" v-if="textHelp" class="text-help">{{textHelp}}</b-form-text>
    </component>
  </div>
</template>
<script>
export default {
  props: ['value', 'placeholder', 'type', 'msg', 'clearable', 'disabled', 'change', 'name', 'action', 'min', 'max', 'optionGroup', 'options', 'specialVerification', 'blur', 'changeDebounce', 'icon', 'append', 'prepend', 'iconLeft', 'textHelp', 'inputDisabledClass', 'noZIndex', 'msgError', 'enableStep'],
  name: 'field-input',
  data () {
    return {
      myValue: undefined,
      myType: undefined,
      myOptionGroup: undefined
    }
  },
  watch: {
    value () {
      this.myValue = this.value
    },
    type () {
      this.myType = this.type
    },
    optionGroup () {
      this.myOptionGroup = this.optionGroup
    }
  },
  mounted () {
    this.myValue = this.value
    this.myType = this.type
  },
  computed: {
    passwordToggleIcon () {
      return this.myType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    clean () {
      this.myValue = ''
      this.onChange()
    },
    togglePassword () {
      this.myType = this.myType === 'password' ? 'text' : 'password'
    },
    onBlur () {
      if (this.blur) this.blur(this.name, this.myValue)
    },
    onChange () {
      if (this.specialVerification) this.myValue = this.specialVerification(this.myValue, this.name)
      this.$debounce(name, () => {
        this.$emit('update:value', this.myValue)
        if (this.change) this.change(this.name, this.myValue)
      }, (this.changeDebounce || 0))
    },
    removeField () {
      this.action.fx(this.name, this.myValue)
    },
    formatterMax (e, max) {
      return String(e).substring(0, max)
    }
  }
}
</script>
<style lang="scss">

span.clean-input {
    height: 0px;
    width: 100%;
    position: relative;
    display: block;
}

.container--icon-right {

  .form-group > div > div {
    display: flex;
  }

  .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .icon--right--input .input-group-text {
    // border-left: 1px solid #d8d6de;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .append-text {
    border-left: 1px solid #d8d6de;
  }
  .disabled-append {
    background-color: transparent;
    > div {
      background-color: #efefef;
    }
  }
}
.container--icon-left {
  .form-group > div > div {
    display: flex;
  }
  .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .icon--left--input .input-group-text {
    // border-left: 1px solid #d8d6de;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .prepend-text {
    border-right: 1px solid #d8d6de;
  }
  .disabled-prepend {
    background-color: transparent;
    > div {
      background-color: #efefef;
    }
  }
}
.clearable > div {
  border-radius: inherit;
}
.zindex {
  z-index: 999;
}
</style>
