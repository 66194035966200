import { environment } from '@/environments/environment'

export default [
  { name: 'getInstructions', url: `${environment.torontoApiUrl}/carrier/{carrier_code}/instructions`, method: 'get' },
  { name: 'getCarrierAnswer', url: `${environment.torontoApiUrl}/instruction-categories`, method: 'get' },
  { name: 'getShipmentsCarriers', url: `${environment.torontoApiUrl}/carrier/{carrier_code}/deliveries`, method: 'get' },
  { name: 'updateInstructions', url: `${environment.torontoApiUrl}/carrier/{carrier_code}/instructions`, method: 'put' },
  { name: 'exportShipmentsCarriers', url: `${environment.torontoApiUrl}/instructions-export`, method: 'get' },
  {
    name: 'postFileInstructions', url: `${environment.torontoApiUrl}/resolve-instructions/bulk/excel`, method: 'post', headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
]