<template>
  <div :ref="`img-${refId}`" :class="`imgLoder-container spinner-border text-${variant}`">
    <img :src="src" :width="width" :class="classImg" :alt="alt" @load="() => onLoadImg(refId)" @error="() => onLoadImg(refId)"/>
  </div>
</template>

<script>
export default {
  name: 'img-loader',
  props: {
    refId: { required: true, type: [String, Number] },
    src: { required: true, type: String },
    variant: { type: String, default: 'primary' },
    alt: { type: String, default: 'Imagen' },
    width: { type: Number, default: 100 },
    classImg: { type: String}
  },
  data () {
    return {}
  },
  methods: {
    onLoadImg (name) {
      this.$refs[`img-${name}`].classList.remove(`text-${this.colorLoader}`, 'spinner-border')
    }
  }
}
</script>

<style lang="scss">
.imgLoder-container{
  overflow: hidden; //Con esto evitamos que se cree movimiento en la vista, en los microsegundos que cambia del loader a la imagen
}
</style>