
import useJwt from '@/auth/jwt/useJwt'
import BaseServices from '@/store/services/index'
const baseService = new BaseServices()

export default {
  state: {
    deliveries: [],
    deliveryTypes: [],
    deliveryStatuses: [],
    deliveryWebTracking: {},
    filterServices: {},
    lastQuery: {},
    deliveriesReturn: [],
    deliveryStatusesFull: [],
    //MIGRA
    deliveriesPlat: [],
    macroStatesPlat: [],
    carriersByCountriesPlatform: [],
    deliveryDetails: {},
    pudos: [],
    placesPlat: [],
    isCancelable: {}
  },
  getters: {
    getDeliveries: state => state.deliveries,
    getDeliveryTypes: state => state.deliveryTypes,
    getDeliveryStatuses: state => state.deliveryStatuses,
    getDeliveryWebTracking: state => state.deliveryWebTracking,
    getReturnWebTracking: state => state.deliveryWebTracking,
    getFilterServices: state => state.filterServices,
    getDeliveriesReturn: state => state.deliveriesReturn,
    getDeliveryStatusesFull: state => state.deliveryStatusesFull,
    //MI
    getDeliveriesPlat: state => state.deliveriesPlat,
    getMacroStatesPlat: state => state.macroStatesPlat,
    getCarriersByCountriesPlatform: state => state.carriersByCountriesPlatform,
    getPlacesPlat: state => state.placesPlat,

    getDeliveryDetails: state => state.deliveryDetails,
    getPudosCompany: state => state.pudos,
    getPudosPlat: state => state.pudos,
    getIsCancelable: state => state.isCancelable
  },
  mutations: {
    setDeliveryDetails(state, val) {
      state.deliveryDetails = val
    },
    setDeliveryWebTracking(state, val) {
      state.deliveryWebTracking = val
    },
    setReturnWebTracking(state, val) {
      state.deliveryWebTracking = val
    },
    setDeliveries(state, val) {
      state.deliveries = {
        rows: val.rows.map(delivery => {
          return {
            ...delivery,
            id: delivery.id,
            shipper_name: delivery.shipper?.name || '',
            carrier_name: delivery.carrier?.code || '',
            status_name: `<span class="badge badge-light-warning">${delivery.status.name || ''}</span>`,
            imported_id: delivery.original_id, // delivery.order?.imported_id || '',
            tracking_number: delivery.order?.imported_id || '',
            // tracking_number: delivery.order?.identifiers?.tracking_number || '',
            level4: delivery.destination?.address?.level4 || '',
            level3: delivery.destination?.address?.level3 || '',
            level2: delivery.destination?.address?.level2 || '',
            level1: delivery.destination?.address?.level1 || '',
            // created_at: delivery.order?.status?.date ? this.context.$options.filters.dbDateToFormat(delivery.order.status.date, 'D T') : null,
            // deadline_date: delivery.extra_fields ? delivery.extra_fields['fecha retiro'] : '',
            booleanNewTicket: delivery.order?.service_type === 'Pasarela' || delivery.shipper?.ticket_force_creation,
            booleanDelete: delivery.status && [1, 4, 5, 12].includes(delivery.status?.id)
            // booleanGenerateLabel: delivery?.status && [1, 4].includes(delivery.status.id)
          }
        }),
        pagination: val.meta.pagination,
        total: val.total
        // meta: val.meta || {},
      }
    },
    setDeliveryTypes(state, val) {
      state.deliveryTypes = val.delivery_types.map(el => ({ id: el, text: el }))
    },
    setDeliveryStatuses(state, val) {
      state.deliveryStatuses = val.rows.map(el => ({ ...el, text: el.name }))
    },
    setFilterServices(state, val) {
      state.filterServices = val
    },
    setDeliveriesReturn(state, val) {
      state.deliveriesReturn = {
        rows: val.rows.map(delivery => {
          return {
            ...delivery
          }
        }),
        total: val.rows.length
      }
    },
    setDeliveryStatusesFull(state, val) {
      state.deliveryStatusesFull = val.rows
    },
    //MIGRA
    setDeliveriesPlat(state, val) {
      state.deliveriesPlat = {
        rows: val.deliveries.map(delivery => {
          return {
            ...delivery,
            customer_contact: `<span class="text-left""><strong>Email:</strong> ${delivery.customer_email}</br><strong>Teléfono:</strong> ${delivery.customer_phone}</span>`
          }
        }),
        total: val.count,
        pagination: val.pagination,
        can_view_slots: val.can_view_slots,
        is_estimated_price: val.is_estimated_price,
        sorting_column: val.sorting_column,
        service_groups: val.service_groups
      }
    },
    setMacroStatesPlat(state, val) {
      state.macroStatesPlat = {
        data: Object.keys(val).map((key) => {
          return {
            text: key,
            ids: val[key],
            id: key
          }
        })
      }
    },
    setCarriersByCountriesPlatform(state, val) {
      state.carriersByCountriesPlatform = val.rows
    },
    setPudosCompany(state, val) {
      state.pudos = val
    },
    setPudosPlat(state, val) {
      state.pudos = val.data
    },
    setPlacesPlat(state, val) {
      state.placesPlat = val
    },
    setIsCancelable(state, val) {
      state.isCancelable = val.data
    }
  },
  actions: {
    fetchDeliveries({ commit }, { queryParams, params } = {}) {
      baseService.callService('getDeliveries', queryParams, params)
        .then(response => {
          const data = {
            rows: response.data.map(delivery => {
              return {
                ...delivery
              }
            }),
            total: response.links.meta.data_count
          }
          commit('setDeliveries', data)
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
    },
    fetchSaveDelivery({ commit, state, dispatch }, { queryParams, params } = {}) {
      const words = !!params?.id ? ['update', 'actualizar'] : ['save', 'guardar']
      baseService.callService(`${words[0]}Delivery`, queryParams, params)
        .then(response => {
          dispatch('fetchDeliveries', { ...state.lastQuery })
          commit('setMessage', { text: `msg-exito-${words[1]}`, type: 'success' })
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: `msg-problema-${words[1]}`, code: err, type: 'alert' })
        })
    },
    fetchDeleteDelivery({ commit, state, dispatch }, { queryParams, params } = {}) {
      baseService.callService('deleteDelivery', queryParams, params)
        .then(response => {
          dispatch('fetchDeliveries', { ...state.lastQuery })
          commit('setMessage', { text: 'msg-exito-eliminar-elemento', type: 'success' })
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: 'msg-problema-eliminar-elemento', code: err, type: 'alert' })
        })
    }
  }
}
