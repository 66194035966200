export default {
  state: {
    getTeamsUsers: []
  },

  getters: {
    getTeamsUsers: state => state.getTeamsUsers
  },

  mutations: {
    setTeamsUsers(state, val) {
      state.getTeamsUsers = {
        rows: val.users
      }
    }
  }
}