import ability from './ability'
import { keyMatch } from './roleRouteCheck'

export const canNavigate = to => {
  return to.matched.some(route => {
    return ability.can(route.meta.action || 'read', route.meta.resource)
  })
}

export const matchRoleUrl = (role, to) => {
  return !!keyMatch[role].includes(to)
}

export const _ = undefined
