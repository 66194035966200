import { environment } from '@/environments/environment'
export default [
  { name: 'getCarrierNY', url: `${environment.newyorkApiUrl}/v2/coverage/carriers/{carrier_id}`, method: 'get' },
  { name: 'getCarriersNY', url: `${environment.newyorkApiUrl}/v2/coverage/carriers`, method: 'get' },
  { name: 'getInternationalCarriers', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/carriers/international`, method: 'get' },
  { name: 'getCarriersForCountry', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries/{country_id}/carriers`, method: 'get', notCancel: true },
  { name: 'getCarriersOriginsModal', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers`, method: 'get' },
  { name: 'getCarrierServicesNY', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services`, method: 'get' },
  { name: 'getInternationalServices', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/carriers/{carrier_id}/services/international`, method: 'get' },
  { name: 'getCarrierServiceRatesNY', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates`, method: 'get' },
  { name: 'getCarrierServiceRateCoveragesNY', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/coverages`, method: 'get' }
]
