<template>
  <div>
    <b-input-group :class="[styleSmall ? 'file-form-area-collection' : 'file-form-area', 'd-flex']">
      <b-form-file
        :type="type || 'text'"
        :class="[classField, 'file-area']"
        :name="name"
        v-model="myValue"
        @input="onChange"
        drop-placeholder="Suelta el archivo aquí"
        :accept="accept"
        label=""
        ref="file-drop-area"
      >
        <template #placeholder>
          <label :class="[styleSmall ? 'd-flex justify-content-left' : '', 'w-100 text-center cursor-pointer m-0']">
            <feather-icon icon="UploadCloudIcon" :size="styleSmall ? '24' : '30'" :class="styleSmall ? 'm-0 mr-1' : 'mt-1'" />
            <p v-if="labelFormFile" :class="styleSmall ? 'mb-0 align-self-center p-0' : 'mb-1'">{{ labelFormFile }}</p>
          </label>
        </template>
        <template #file-name>
          <div :class="[styleSmall ? 'text-file-small' : 'text-file', 'd-flex justify-content-between align-items-center bg-gray-input']" v-if="myValue">
            <span class="text-truncate">{{ myValue.name }}</span>
          </div>
          <label :class="[styleSmall ? 'd-flex justify-content-left' : '', 'w-100 text-center cursor-pointer m-0']" v-if="!myValue">
            <feather-icon icon="UploadCloudIcon" :size="styleSmall ? '24' : '30'" :class="styleSmall ? 'm-0 mr-1' : 'mt-1'" />
            <p v-if="labelFormFile" :class="styleSmall ? 'mb-0 align-self-center p-0' : 'mb-1'">{{ labelFormFile }}</p>
          </label>
        </template>
      </b-form-file>
      <b-input-group-append :class="styleSmall ? 'remove-botton-small' : 'remove-botton'" v-if="myValue">
        <b-button variant="outline" class="border-0" size="sm" @click="myValue = null;">
          <feather-icon icon="XIcon" size="24" />
        </b-button>
      </b-input-group-append>
      <b-form-invalid-feedback v-if="msg && msg.text">
        {{msg.text}}
      </b-form-invalid-feedback>
      <b-form-text id="input-live-help" v-if="textHelp" class="text-help">{{textHelp}}</b-form-text>
    </b-input-group>
    <b-form-invalid-feedback v-if="msgInvalidFile">
      <span class="ml-2">{{ msgInvalidFile }}</span>
    </b-form-invalid-feedback>
    <b-form-invalid-feedback v-if="msg && msg.text">
      {{msg.text}}
    </b-form-invalid-feedback>
  </div>
</template>
<script>

export default {
  props: ['value', 'placeholder', 'msg', 'change', 'name', 'accept', 'classField', 'labelFormFile', 'textHelp', 'type', 'styleSmall'],
  name: 'field-file-drop-area',
  data () {
    return {
      myValue: undefined,
      myType: undefined,
      myOptionGroup: undefined,
      msgInvalidFile: ''
    }
  },
  watch: {
    value () {
      this.myValue = this.value
    }
  },
  methods: {
    onChange (val) {
      if (val) this.validateFile(val)
      this.$emit('update:value', this.myValue)
      if (this.change) this.change(this.name, this.myValue)
    },
    validateFile(val) {
      if (val.type.includes('csv') && val.size > 30 * 1024 * 1024) {
        this.msgInvalidFile = 'El archivo de tipo CSV no puede superar los 30MB, se recomienda usar el formato .ZIP'
        this.myValue = undefined
      } else if (val.type.includes('zip') && val.size > 15 * 1024 * 1024) {
        this.msgInvalidFile = 'El archivo de tipo ZIP no puede superar los 15MB'
        this.myValue = undefined
      } else {
        this.msgInvalidFile = ''
      }
    }
  }
}
</script>
<style lang="scss">
div[fieldtype="FieldFileDropArea"] > .invalid-feedback{
  display: block !important;
}
.hide-button-browse > label {
  &::after {
    display: none !important;
    cursor: pointer !important;
  }
}
.file-form-area > .file-area {
  height: 6rem !important;
}
.file-form-area > .hide-button-browse > label {
  cursor: pointer !important;
  height: 6rem !important;
  align-content: center;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 10 !important;
  width: 100% !important;
}
.bg-gray-input {
  background-color: #f7f7f9 !important;
}
.remove-botton {
  background-color: #f7f7f9 !important;
  position: absolute;
  right: 1px;
  z-index: 10;
  top: 1.65rem;
}
.text-file {
  border-radius: 5px;
  padding: 0.6rem;
}
.text-file-small {
  border-radius: 5px;
  padding: 0.1rem;
}
.remove-botton-small {
  position: absolute;
  right: 1px;
  z-index: 10;
  top: 0.1rem;
  right: 1rem;
  border-radius: 5px;
}
</style>
