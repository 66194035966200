import Vue from 'vue'
import Vuex, { Store } from 'vuex'

// Modules
import * as fb from '@/firebase'
//import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import login from './login'
import global from './global'
import notifications from './notifications'
import organizations from './organizations'
import folders from './folders'
import shippers from './shippers'
import carriers from './carriers'
import carriersNY from './carriers/indexNY.js'
import companies from './companies'
import deliveries from './deliveries'
import advancedRules from './advancedRules'
import pricing from './pricing'
import pudos from './pudos'
import storeManagement from './storeManagement'
import integrations from './integrations'
import billing from './billing'
import returns from './returns'
import tracking from './tracking'
import marketcenter from './marketcenter'
import ratesQuoter from './ratesQuoter'
import originsDestinations from './originsDestinations'
import quoter from './quoter'
import pricingWeb from './pricingWeb'
import enviaFlash from './envia-flash'
import * as fx from './notifications/fx'
import collectionOnDelivery from './collectionOnDelivery'
import webhooks from './webhooks'
import team from './team'
import margins from './margins'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    //'app-ecommerce': ecommerceStoreModule,
    login,
    global,
    notifications,
    organizations,
    folders,
    shippers,
    carriers,
    carriersNY,
    companies,
    deliveries,
    advancedRules,
    pricing,
    pudos,
    storeManagement,
    integrations,
    billing,
    returns,
    tracking,
    marketcenter,
    ratesQuoter,
    originsDestinations,
    quoter,
    pricingWeb,
    enviaFlash,
    collectionOnDelivery,
    webhooks,
    team,
    margins
  },
  strict: process.env.DEV
})

// Notificaciones campana de Madagascar
/* fb.notificationsCollection.onSnapshot(snapshot => {
  const notificationsArray = fx.parseRows(snapshot.docs)
  store.commit('setNotifications', notificationsArray)
  store.commit('setNotificationsRead', fx.getNotificationsRead(notificationsArray))
}) */

export default store
