<template>
  <div>
    <div class="custom-fieldSelect">
      <!-- ref="fieldSelectInput" -->
      <div class="d-flex form-control align-content-center">
        <input
          type="text"
          v-model="filterText"
          @click="toggleDropdown"
          @input="filterOptions"
          @blur="hideDropdown"
          :placeholder="placeholder || 'Seleccione una opción'"
          :class="['fieldCustom-input', 'input-icon-validate-'+(msg && msg.type? msg.type : '')]"
          :state="(typeof msg === 'undefined') ? undefined : false"
          :disabled="disabled"
          autocomplete="off"
        />
        <feather-icon
          v-if="!!filterText"
          @click="cleanSelect"
          icon="XIcon"
          class="fieldCustom-close"
          size="18"
          v-b-tooltip.hover.top
          title="Limpiar selección"
        />
        <feather-icon :icon="showDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'" @click="toggleDropdown"/>
      </div>
      <div v-if="showDropdown && myValue.length" class="options-dropdown-FCS shadow-sm" @mousedown="handleDropdownClick">
        <div
          v-for="option in myValue"
          :key="option.id"
          class="option"
          :class="{' text-white bg-primary': (selectedOption && selectedOption.id === option.id)}"
        >
          {{ option.text }} 
        </div>
      </div>
      <div class="border p-1 options-dropdown-FCS shadow-sm" v-if="showDropdown && !myValue.length" :disabled="true">
        <span>{{$t('No hay opciones cargadas.')}}</span>
      </div>
    </div>
    <small class="text-primary" v-if="showInfoText && filterText && !msg" ><feather-icon icon="InfoIcon"/> Debe completar el campo</small>
    <b-form-invalid-feedback v-if="msg && msg.text" :state="(typeof msg === 'undefined') ? undefined : false">
      {{msg.text}}
    </b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  name: 'field-custom-select',
  props: ['options', 'change', 'value', 'msg', 'placeholder', 'disabled', 'validationName', 'name'],
  data() {
    return {
      myValue: [],
      filterText: '',
      showDropdown: false,
      selectedOption: null,
      inputHasFocus: false,
      filteredOptions: [],
      allValues: [],
      showInfoText: false
    }
  },
  mounted() {
    this.setOptionSetting()
  },
  watch: {
    options: {
      handler() {
        this.setOptionSetting()
      },
      deep: true
    },
    value () {
      this.selectedOption = this.value
      if (this.value?.text) this.filterText = this.value?.text
      else this.filterText = this.value
    }
  },
  methods: {
    handleDropdownClick(event) {
      const regex = /: .+/
      const text = event.target.innerText

      const optionText = (regex.test(text) || !text.includes(':')) ? text : `${text} `
      const selectedOption = this.myValue.find(option => option.text === optionText)

      if (selectedOption) {
        this.selectedOption = selectedOption
        this.filterText = selectedOption.text
        this.showDropdown = false
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
      if (this.showDropdown) this.myValue = this.allValues
    },
    selectOption(option) {
      this.selectedOption = option
      this.filterText = option.allowInput ? `${option.text} ` : option.text
      this.showDropdown = false
    },
    filterOptions() {
      this.inputHasFocus = true

      if (!this.selectedOption?.allowInput) {
        this.filterList()
        return
      }
      const limitString = this.filterText.includes(': ')

      const selectedOpt = this.myValue.map(option => {
        if (option.id === this.selectedOption?.id) {
          return {
            ...option,
            text: limitString ? this.filterText : `${this.selectedOption?.text.split(':')[0]}: `
          }
        }
        return option
      })

      this.myValue = selectedOpt

      if (limitString) {
        this.allValues = selectedOpt
        this.selectedOption = this.myValue.find(option => option.id === this.selectedOption?.id)
      }
      this.showDropdown = true
    },
    filterList() {
      this.myValue = this.allValues.filter(option =>
        option.text.toLowerCase().includes(this.filterText.toLowerCase())
      )
    },
    setOptionSetting () {
      if (!this.options?.length) return
      const settingPrefix = this.options.map(option => {
        // Verifica si el elemento tiene allowInput antes de agregar ': '
        if (option.allowInput) {
          return { ...option, text: `${option.text}: ` }
        }
        return option
      })
      this.myValue = settingPrefix
      this.allValues = settingPrefix
    },
    hideDropdown() {
      const regex = /: .+/
      this.showInfoText = (this.filterText.includes(':') && !regex.test(this.filterText))
      if (!this.filterText && !this.selectedOption) {
        this.showDropdown = false
        return
      }
      if ((!this.filterText && regex.test(this.selectedOption?.text)) ||
          (this.filterText && this.filterText.length !== this.selectedOption?.text?.length && regex.test(this.selectedOption.text)) ||
          (this.filterText.includes(':') && !this.checkCoincidences(this.filterText))
      ) {
        const changeDefaul = !this.checkCoincidences(this.filterText)
        this.filterText = changeDefaul ? `${this.options.filter(el => el.id === this.selectedOption?.id)[0].text}: ` : `${this.selectedOption?.text.split(':')[0]}: `
        if (changeDefaul) this.selectedOption.text = this.filterText
        const newval = this.allValues.map(option => {
          if (option.id === this.selectedOption?.id) {
            return {
              ...option,
              text: this.filterText
            }
          }
          return option
        })
        this.allValues = newval
      }
      else {
        this.filterText = this.selectedOption?.text
      }
      this.showDropdown = false
      
      if (this.showInfoText) this.$emit('update-special-validation-name', this.name)
      else this.$emit('update-special-validation-name', '')
      
      this.updateFormData()
    },
    updateFormData() {
      this.$emit('update:value', this.selectedOption)
      if (this.change) this.change(this.name, this.selectedOption)
    },
    checkCoincidences(value) {
      const fixedText = value.split(':')[0].trim()
      return this.options.some(function(option) {
        return option.allowInput && option.text === fixedText
      })
    },
    cleanSelect () {
      this.selectedOption = null
      this.filterText = ''
      this.showInfoText = false
      this.showDropdown = false
      this.$emit('update-special-validation-name', '')
    }
  }
}
</script>

<style scoped>
.custom-fieldSelect {
  position: relative;
}
.custom-fieldSelect > div{
  align-items: center;
  padding-right: 5px;
}
.fieldCustom-input{
  border: none;
  color: #6e6b7b;
}
.fieldCustom-close {
  margin: 0 5px;
  cursor: pointer;
}
.options-dropdown-FCS {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  max-height: 365px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9;
}
.options-dropdown-FCS {
  border-radius: 0.357rem;
}
.options-dropdown-FCS .option {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.options-dropdown-FCS .option:hover {
  background-color: #04438929;
}
</style>