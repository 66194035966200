export default {
  state: {
    getCountriesPricingWeb: []
  },

  getters: {
    getCountriesPricingWeb: state => state.getCountriesPricingWeb
  },
  mutations: {
    setCountriesPricingWeb(state, val) {
      state.getCountriesPricingWeb = {
        rows: val.rows.map(el => ({...el, text: el.name}))
      }
    }
  }
}
