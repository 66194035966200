import BaseServices from '@/store/services/index'
const baseService = new BaseServices()
  
export default {
  state: {
    getWebhooks: [],
    getWebhookID: [],
    getTypeWebhook: [],
    getStatusWebhook: [],
    postWebhook: []
  },

  getters: {
    getWebhooks: state => state.getWebhooks,
    getWebhookID: state => state.getWebhookID,
    getTypeWebhook: state => state.getTypeWebhook,
    getStatusWebhook: state => state.getStatusWebhook,
    postWebhook: state => state.postWebhook
  },

  mutations: {
    setWebhooks(state, val) {
      state.getWebhooks = {
        rows: val.response.data.map(webhooks => {
          return {
            ...webhooks
          }
        }),
        pagination : val.response.links
      }
    },
    setWebhookID(state, val) {
      state.getWebhookID = val.response
    },
    setTypeWebhook(state, val) {
      state.getTypeWebhook = val.response.data.webhook_types.map(webhook => {
        return {
          ...webhook,
          default_headers: val.response.data.default_headers,
          text: webhook.resource_name,
          selectors: webhook.selectors.map(field => {
            return {
              ...field,
              id: field.value,
              text: field.name
            }
          })
        }
      })
    },
    setStatusWebhook(state, val) {
      state.getStatusWebhook = val.response.data.statuses.map(webhook => {
        return {
          ...webhook
        }
      })
    },
    setWebhook(state, val) {
      state.postWebhook = {
        rows: val.rows.map(webhook => {
          return {
            ...webhook
          }
        })
      }
    }
  }
}