import useJwt from '@/auth/jwt/useJwt'
import BaseServices from '@/store/services/index'
const baseService = new BaseServices()

export default {
  state: {
    prices: [],
    countries: [],
    origins: [],
    carriers: [],
    services: []
  },

  getters: {
    getPricingUniversal: state => state.prices,
    getCountriesPricing: state => state.countries,
    getOriginsPricing: state => state.origins,
    getCarriersPricing: state => state.carriers,
    getServicesPricing: state => state.services
  },

  mutations: {
    setFolders(state, val) {
      state.folders = {
        rows: val.rows.map(folder => {
          return {
            ...folder,
            folder_name: folder.name,
            organization_name: folder.organization?.name
          }  
        }),
        total: val.total
      }
    },
    setPricingUniversal(state, val) {
      state.prices = val.rows.map(el => el)
    },
    setCountriesPricing(state, val) {
      state.countries = val.rows.map(el => ({...el, text: el.name, _id: el.id}))
    },
    setOriginsPricing(state, val) {
      // const full_places = val.meta.places.concat(val.places).map(el => ({...el, text: el.name}))
      // const places = { level1: []}
      // //   level1: val.meta.map(el => el.level_num === 1)
      // // }
      // const max_level = val.places[0].level_num || 3
      // for (let index = max_level; index > 0; index--) {
      //   full_places.map(fp1 => {
      //     if (fp1.level_num === index && index !== 1) {
      //       const indexParent = full_places.findIndex(fp2 => fp2.id === fp1.parent_id)
      //       full_places[indexParent][`level${fp1.level_num}`].push(fp1)
      //     }
      //   })
      // }
      state.origins = val.meta.places.concat(val.places).map(el => ({...el, text: el.name}))
    },
    setCarriersPricing(state, val) {
      state.carriers = val.rows.map(el => ({...el, text: el.name, _id: el.id}))
    },
    setServicesPricing(state, val) {
      state.services = val.rows.map(el => ({...el, text: el.name}))
    }
  },

  actions: {
    downloadExcelPricing ({commit}, { params, queryParams = {}, onSuccess = null}) {
      baseService.callService('downloadExcelPricing', queryParams, params, { fullResponse: true })
        .then(({ data }) => {
          const fileName = `Pricing ${1}.xls`
          try {            
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(data, fileName)
            } else {
              const objectUrl = URL.createObjectURL(data)
              window.open(objectUrl)
            }
          } catch (exc) {
            console.error(exc)
          }
          if (onSuccess) onSuccess()
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
    }
  }
}