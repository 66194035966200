export default {
  state: {
    warehouses: [],
    carriers: []
    // deliveries: [],
  }, 
  getters: {
    getWarehousesFlash: state => state.warehouses,
    getDeliveriesFlash: state => state.deliveries,
    getCarriersFlash: state => state.carriers
  },
  mutations: {
    setWarehousesFlash (state, val) {
      state.warehouses = val.rows.map((warehouse, index) => {
        return {
          ...warehouse,
          id: index,
          text: warehouse.name,
          addressWarehouse: warehouse.address.full_address,
          phoneWarehouse: warehouse.contact.phone,
          emailWarehouse: warehouse.contact.email
        }
      })
    },
    setCarriersFlash (state, val) {
      state.carriers = val.flash.carriers
    }
  },
  actions: {

  }
}