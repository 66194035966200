import BaseServices from '@/store/services/index'
const baseService = new BaseServices()
  
export default {
  state: {
    getPudosForType: [],
    getCarriersName: [],
    postPudo: [],
    getCarriersPudos: [],
    putPudo: [],
    putSchedules: [],   
    getDeliveriesPudos: [],
    getDeliveryStatusesPudo: [],
    getZipcode: []
  },

  getters: {
    getPudosForType: state => state.getPudosForType,
    getCarriersName: state => state.getCarriersName,
    postPudo: state => state.postPudo,
    getCarriersPudos: state => state.getCarriersPudos,
    putPudo: state => state.putPudo,
    putSchedules: state => state.putSchedules,
    getDeliveriesPudos: state => state.getDeliveriesPudos,
    getDeliveryStatusesPudo: state => state.getDeliveryStatusesPudo,
    getZipcode: state => state.getZipcode
  },

  mutations: {
    setPudosForType(state, val) {
      state.getPudosForType = {
        rows: val.rows.map(pudos => {
          return {
            ...pudos
          }
        }),
        pagination : val.links
      }
    },
    setCarriersName(state, val) {
      state.getCarriersName = {
        rows: val.rows.map(pudos => {
          return {
            ...pudos
          }
        })
      }
    },
    setPudo(state, val) {
      state.postPudo = {
        rows: val.rows.map(pudos => {
          return {
            ...pudos
          }
        })
      }
    },
    setCarriersPudos(state, val) {
      state.getCarriersPudos = val.rows.map(el => ({id:el.carrier_code, name:el.carrier_name, text: el.carrier_name, code: el.carrier_code, logo: el.logo, country_id: el.country_id}))
    },
    setPudoId(state, val) {
      state.putPudo = {
        rows: val.rows.map(pudos => {
          return {
            ...pudos
          }
        })
      }
    },
    setSchedules(state, val) {
      state.putSchedules = {
        rows: val.rows.map(pudos => {
          return {
            ...pudos
          }
        })
      }
    }, 
    setDeliveriesPudos(state, val) {
      state.getDeliveriesPudos = {
        rows: val.rows.map(el => {
          return {
            ...el,
            disabled_checkbox: ![11, 54, 29].includes(el.status.id),
            status: {
              ...el.status,
              text: el.status.name
            }
          }
        }),
        pagination : val.links
      }
    },
    setDeliveryStatusesPudo(state, val) {
      state.getDeliveryStatusesPudo = val.rows.map(el => ({...el, text: el.name}))
    },
    setZipcode(state, val) {
      state.getZipcode = val.zip_codes.map(el => ({'name': el, 'id': el}))
    }
  },
  actions: { 
    downloadExcelAccordingToType({ commit }, { params = {}, queryParams = {}, onSuccess = null, onError = null, typeExcel = null}) {
      baseService.callService(typeExcel.name, queryParams, params, { fullResponse: true })
        .then(({ data }) => {
          const fileName = `${typeExcel.title}.xlsx`
          const objectUrl = URL.createObjectURL(data)
          const link = document.createElement('a')
          link.href = objectUrl
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          if (onSuccess) onSuccess()
        })
        .catch(err => {
          if (onError) onError()
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
    }
  }
}