<template>
  <div>
    <b-form-group>
      <b-form-checkbox
        :value="true"
        v-model="allSelected"
        v-if="checkAllActive"
        @change="checkAll"
        :name="name"
        :disabled="disabled"
        :inline="align === 'h'"
        :state="(typeof msg === 'undefined') ? undefined : false"
      >
        {{ 'Todos' }}
      </b-form-checkbox>
      <b-form-checkbox
        v-for="option in options"
        :key="option.id"
        :value="option"
        v-model="myValue"
        v-if="option.visible || typeof option.visible === 'undefined'"
        :class="[option.class, optionClass]"
        @change="onChange"
        :name="name"
        :disabled="disabled"
        :inline="align === 'h'"
        :state="(typeof msg === 'undefined') ? undefined : false"
      >
        {{ option.text }}
      </b-form-checkbox>
      <b-form-invalid-feedback v-if="msg && msg.text">
        {{msg.text}}
      </b-form-invalid-feedback>
    </b-form-group>
    <custom-alert-field v-if="customMessage" class="new-line" :text="customMessage && customMessage.text" :type="customMessage && customMessage.type? customMessage.type : ''"/>
  </div>
</template>
<script>
import CustomAlertField from '@/views/components/custom/form-render/fields/CustomAlertField.vue'

export default {
  name: 'field-checkbox',
  components: { CustomAlertField },
  props: ['options', 'value', 'align', 'msg', 'disabled', 'change', 'name', 'optionClass', 'multiple', 'useAllCheck', 'customMessage'],
  data () {
    return {
      myValue: [],
      myOptions: [],
      allSelected: false,
      checkAllActive: this.useAllCheck || false
    }
  },
  mounted() {
    this.setChecked()
  },
  watch: {
    value: {
      handler() {
        this.setChecked()
      }, deep: true
    },
    options () {
      this.setChecked()
    }
  },
  methods: {
    setChecked() {
      this.allSelected = false
      if (!!this.multiple) {
        this.myOptions = this.options
        const currentValue = this.value && this.options ? this.options.filter(({id}) => this.value.findIndex(val => val.id === id) !== -1) : []
        this.myValue = this.$getUniqueList(currentValue.concat(this.value), 'id')
      } else {
        this.myValue = !!this.value?.id || this.value ? this.options[0] : []
      }
    },
    onChange(as, ds) {
      let returnValue = this.myValue
      if (!this.multiple) returnValue = !!returnValue[0] || !!returnValue
      this.$emit('update:value', returnValue)
      if (this.change) this.change(this.name, returnValue)
    },
    checkAll() {
      if (this.allSelected) {
        this.myValue = [...this.myOptions]
      } else {
        this.myValue = []
      }
    }
  }
}
</script>
<style lang="scss">
</style>
