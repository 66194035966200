import { environment } from '@/environments/environment'
export default [
  { name: 'getShippersByOrganizationSimplifiedQueryParams', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers?simplified=true`, method: 'get' },
  { name: 'getWebhooks', url: `${environment.buenosairesApiUrl}/v1/webhooks`, method: 'get' },
  { name: 'getWebhookID', url: `${environment.buenosairesApiUrl}/v1/webhook`, method: 'get' },
  { name: 'postWebhook', url: `${environment.buenosairesApiUrl}/v1/webhook`, method: 'post'},
  { name: 'putWebhook', url: `${environment.buenosairesApiUrl}/v1/webhook`, method: 'put'},
  { name: 'testWebhook', url: `${environment.buenosairesApiUrl}/v1/webhook-test`, method: 'post'},
  { name: 'toggleActiveWebhook', url: `${environment.buenosairesApiUrl}/v1/webhook/toggle-active`, method: 'patch'},
  { name: 'getTypeWebhook', url: `${environment.buenosairesApiUrl}/v1/resources`, method: 'get' },
  { name: 'getStatusWebhook', url: `${environment.buenosairesApiUrl}/v1/statuses`, method: 'get' }  
]