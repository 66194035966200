<template>
    <gmap-map
      :center="center"
      :zoom="zoom || 16"
      :style="sizeMap"
    >
    <div v-if="!noMarker">
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :title="m.title"
        :clickable="true"
        :draggable="m.draggable && !multipleMarker"
        @click="center=m.position"
        @dragend="updateCoordinates($event, index)"
      ></gmap-marker>
    </div>
    </gmap-map>
  </template>
<script>
import { gmapApi } from 'vue2-google-maps'
export default {
  props: ['infoMap', 'update', 'noMarker', 'size', 'multipleMarker', 'zoom', 'full_address'],
  data () {
    return {  
      coordinates: null,       
      center: {},
      markers: [],
      sizeMap: this.size || {width: '100%', height: '500px'},
      coordinates_default: {
        latitude: -33.452445,
        longitude: -70.669787
      },
      keyRender: 0
    }
  },
  watch: {
    infoMap () {
      this.setMap() 
    },
    'full_address': {
      handler(value) {
        // se recomienda buscar la propiedad en el input changeDebounce
        this.geoCodedLocation()
      }
    }
  },
  updated() {
    setTimeout(() => {
      if (this.keyRender === 0) {
        this.geoCodedLocation()
        this.keyRender++
      }
    }, 1000)
  },
  computed: {
    google: gmapApi
  },
  mounted () {
    this.setMap() 
  },
  methods: {
    updateCoordinates(location) {
      const coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
      this.geocodedAddress(coordinates)
    },
    setMap () {
      !!this.multipleMarker ? this.setMultipleMarkers() : this.setOnlineMarker()
    },
    setOnlineMarker () {
      const infoMarker = {
        position: {lat: Number(this.infoMap?.latitude) || this.coordinates_default.latitude, lng: Number(this.infoMap?.longitude) || this.coordinates_default.longitude}, 
        title: this.infoMap?.title || 'Titulo',
        draggable: this.infoMap?.draggable || false
      }
      this.markers = [infoMarker]
      this.center = infoMarker.position
    },  
    setMultipleMarkers () {
      this.infoMap.map(item => {
        this.markers.push({
          position: {lat: Number(item.latitude) || this.coordinates_default.latitude, lng: Number(item.longitude) || this.coordinates_default.longitude}, 
          title: item.title || 'Titulo',
          draggable: item.draggable || false
        })
      })
      this.center = {lat: Number(this.infoMap[0]?.latitude) || this.coordinates_default.latitude, lng: Number(this.infoMap[0]?.longitude) || this.coordinates_default.longitude}
    },
    geocodedAddress(coordinates) {
      const geocoder = new this.google.maps.Geocoder()
      geocoder
        .geocode({ location: coordinates })
        .then((response) => {
          this.emitDataResult(response, coordinates)
        })
        .catch(e => console.error(e))
    },
    geoCodedLocation() {
      if (this.full_address) {
        const geocoder = new this.google.maps.Geocoder()
        geocoder
          .geocode({ address: this.full_address })
          .then((response) => {
            this.infoMap.latitude = response.results[0].geometry.location.lat()
            this.infoMap.longitude = response.results[0].geometry.location.lng()
            this.setMap()
            this.emitDataResult(response)
          })
          .catch(e => console.error(e))
      }
    },
    emitDataResult(response, coordinates = null) {
      const result = {
        id: response.results[0].place_id,
        formatted_address: response.results[0].formatted_address,
        text: response.results[0].formatted_address,
        location: coordinates ?? { lat: response.results[0].geometry.location.lat(), lng: response.results[0].geometry.location.lng()}
      }
      if (this.update) this.update(result)
    }
  }
}
</script>