<template>
  <div :class="[!!icon ? 'container--icon-right': '', !!iconLeft ? 'container--icon-left' : '']">
    <component :is="optionGroup ? 'b-input-group' : 'b-form-group'"
      v-bind="optionGroup"
    >
    <div>
      <b-input-group-prepend is-text v-if="!!iconLeft" class="icon--left--input prepend-text">
        <feather-icon :icon="iconLeft.icon" :size="iconLeft.size || '18'" :stroke-width="iconLeft.stroke || '2'" :color="iconLeft.color || '#6e6b7b'"/>
      </b-input-group-prepend>
      <b-form-input
        :placeholder="placeholder"
        type="text"
        autocomplete="off"
        :class="[!!action? 'with-action' : '']"
        :name="name"
        :disabled="disabled"
        v-model="myValue"
        @input="onSearch"
        @blur="onBlur"
        :state="(typeof msg === 'undefined') ? undefined : false"
      />
      <b-input-group-append is-text v-if="!!icon" class="icon--right--input append-text">
        <feather-icon :icon="icon.icon" :size="icon.size || '18'" :stroke-width="icon.stroke || '2'" :color="icon.color || '#6e6b7b'" :class="icon.icon === 'LoaderIcon' ? 'spinner' : ''"/>
      </b-input-group-append>
      <div class="main-box-autocomplete" v-if="showList && myValue" :style="customizeTop">
        <div class="element-box-autocomplete text-center" v-if="noResults && !loadingList && searchOnType.nChar <= myValue.length">
          <span>{{$t('Sin coincidencias para')}} <em>{{ myValue }}</em>.</span>
        </div>
        <div class="element-box-autocomplete text-center" v-if="loadingList && searchOnType.nChar <= myValue.length">
          <span>
            {{$t('Buscando coincidencias para')}} <em>{{ myValue }}</em><br>
            <feather-icon v-if="showSpinner" icon="LoaderIcon" class="spinner m-1" size="2x"/>
          </span>
        </div>
        <div class="element-box-autocomplete text-center" v-if="myValue.length < searchOnType.nChar">
          <span>{{$t('msg-nchars', {key: searchOnType.nChars || 3})}}</span>
        </div>
        <div class="block-suggestions" v-if="!!suggestions_address_validate_google && !loadingList && myValue.length >= searchOnType.nChar">
          <span >Sugerencias encontradas</span>
        </div>
        <div v-if="myValue.length >= searchOnType.nChar && !noResults && !loadingList">
          <div class="element-box-autocomplete" v-for="(option, index) in myOptions" :key="index" @click="() => onChange(option)">
            <span>{{option.text}}</span>
          </div>
        </div>
      </div>
    </div>
      <b-form-invalid-feedback v-if="msg && msg.text">
        {{msg.text}}
      </b-form-invalid-feedback>
    </component>
    <span v-if="clearable" :style="{opacity: myValue && !noSeeClearable ? 1 : !!noSeeClearable ? 0 : 0}" class="clean-input mt-auto mb-auto" @click="clean">
    </span>
  </div>
</template>
<script>
export default {
  props: ['value', 'id', 'placeholder', 'msg', 'clearable', 'disabled', 'change', 'name', 'action', 'optionGroup', 'searchOnType', 'clearAfterSelect', 'icon', 'noShowSpinner', 'noSeeClearable', 'iconLeft'],
  name: 'field-autocomplete',
  data () {
    return {
      myValue: undefined,
      myOptionGroup: undefined,
      myOptions: [],
      showList: false,
      noResults: true,
      loadingList: false,
      isEditing: false,
      showSpinner: true,
      suggestions_address_validate_google: false,
      customizeTop: ''
    }
  },
  watch: {
    value () {
      if (!this.isEditing) this.myValue = this.value?.text || ''
    },
    noShowSpinner () {
      this.showSpinner = !this.noShowSpinner
    },
    optionGroup () {
      this.myOptionGroup = this.optionGroup
    }
  },
  mounted () {
    this.showSpinner = !this.noShowSpinner
    this.myValue = this.value?.text || ''
    const top = this.icon?.customizeTopInMain || this.iconLeft?.customizeTopInMain
    this.customizeTop = top ? `top: ${top};` : ''
  },
  methods: {
    clean () {
      this.myValue = ''
      this.onChange()
    },
    onBlur (e) {
      setTimeout(() => {
        this.showList = false
      }, 200)
    },
    onChange (option) {
      this.myValue = option?.text || ''
      this.isEditing = false
      this.$emit('update:value', option)
      if (this.change) this.change(this.name, option)
      this.showList = false
      if (this.clearAfterSelect && option) this.clean()
    },
    onSearch (search) {
      // if (this.specialVerification) this.myValue = this.specialVerification(this.myValue)
      this.isEditing = true
      this.$emit('update:value', this.myValue)
      this.showList = true
      this.loadingList = true
      // const myElement = document.querySelector(`#${this.id} .vs__dropdown-toggle > .vs__selected-options > input`)
      this.myOptions = []
      if (search && search.length >= parseInt(this.searchOnType.nChars || 3)) {
        // this.searched = search
        this.noResults = false
        this.$debounce(this.id, () => {
          this.searchOnType.fx(search)
            .then(resp => {
              if (resp.length > 0) {
                this.suggestions_address_validate_google = resp.suggestions_address_validate_google
                this.myOptions = [...([]).concat(resp)]
              } else {
                this.noResults = true
              }
              this.loadingList = false
              // this.keySelect++
            })
            .catch(err => {
              this.loadingList = false
              this.noResults = true
            })
        }, this.searchOnType.debounce)
      }

      // this.$emit('update:value', { text: this.myValue })
      // if (this.change) this.change(this.name, { text: this.myValue })
    },
    removeField () {
      this.action.fx(this.name, this.myValue)
    }
  }
}
</script>
<style lang="scss">

span.clean-input {
    height: 0px;
    width: 100%;
    position: relative;
    display: block;
}

.clean-input::before{
    position: relative;
    z-index: 10;
    left: calc(100% - 25px);
    cursor: pointer;
    // padding: 5px;
    padding: 0px 7px;
    top: -27px;
    color: #6e6b7b;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 40 40' fill='none' stroke='gray' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x w-4 h-4 mt-1'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
}
.main-box-autocomplete {
  position: absolute;
  background-color: white;
  // border: 1px solid transparent;
  overflow: hidden;
  width: calc(100% - 2rem);
  border-radius: 5px;
  z-index: 3;
  box-shadow: 0px 4px 25px 0px rgb(0 0 0 / 10%);
}

.element-box-autocomplete{
  cursor: pointer;
  padding: 0.5rem 1rem;
  &:hover {
    background: rgba(4, 67, 137, 0.12) !important;
    color: #044389 !important;
    white-space: nowrap;
  }
}
.block-suggestions{
  cursor: not-allowed;
  padding: 0.5rem 1rem;
  background-color: #efefef;
  color: #6e6b7b;
}

.container--icon-right {
.main-box-autocomplete {
  top: 2.8rem;
}

.form-group > div > div {
   display: flex;
}

.form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.icon--right--input .input-group-text {
  // border-left: 1px solid #d8d6de;
  border-top-left-radius: 0 ;
  border-bottom-left-radius: 0;
  
}
.append-text {
  border-left: 1px solid #d8d6de;
}
.disabled-append {
  background-color: transparent;
  > div {
    background-color: #efefef;
  }
}
}
.container--icon-left {
  .main-box-autocomplete {
    top: 2.8rem;
  }

  .form-group > div > div {
    display: flex;
  }
  .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .icon--left--input .input-group-text {
    // border-left: 1px solid #d8d6de;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .prepend-text {
    border-right: 1px solid #d8d6de;
  }
  .disabled-prepend {
    background-color: transparent;
    > div {
      background-color: #efefef;
    }
  }
}
</style>
