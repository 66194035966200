var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    '--tracking_color': _vm.styleColor, 
    '--tracking_color_b': _vm.styleColor ? _vm.hexToRgba(_vm.styleColor, 0.5): ''
    })},[_c('div',{staticClass:"container d-none d-md-block"},[_c('div',{staticClass:"row chronology"},_vm._l((_vm.timeline),function(stage,index){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(stage.tooltip),expression:"stage.tooltip",modifiers:{"hover":true,"bottom":true}}],key:index,staticClass:"chronology-step horizontal-center",class:[
            {'active': stage.active, 'disabled': !stage.active}, 
            (!_vm.styleColor ? _vm.color || stage.color || 'light' : 'personalized'),
            _vm.type
            ],style:([{'flex-direction': 'column', 'width': ((100 / _vm.timeline.length) + "%") }])},[_vm._m(0,true),_c('div',{staticClass:"chronology-dot"}),_c('div',{staticClass:"chronology-stepname text-center v-step-5-1",style:({ 'color': stage.colorTitle })},[_vm._v(_vm._s(stage.title))]),(stage.subtitle)?_c('div',{staticClass:"chronology-info text-center",style:({ 'color': stage.colorSubtitle })},[_vm._v(_vm._s(stage.subtitle))]):_vm._e(),(stage.date)?_c('div',{staticClass:"chronology-info text-center",style:({ 'color': stage.colorTitle })},[_c('small',[_vm._v(_vm._s(stage.date))])]):_vm._e(),(_vm.verifyTypeTimeline(stage, index))?_c('div',{staticClass:"chronology-icon v-step-4-1"},[_c('feather-icon',{class:stage.iconClass,attrs:{"icon":_vm.icon && !stage.icon ? (stage.active ? _vm.icon : 'void') : stage.icon,"size":"2x"}}),(_vm.lastActiveIndex === index)?_vm._t("iconSlot"):_vm._e()],2):_vm._e()])}),0)]),_c('app-timeline',{staticClass:"d-md-none"},_vm._l((_vm.timeline),function(stage,index){return _c('app-timeline-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(stage.tooltip),expression:"stage.tooltip",modifiers:{"hover":true}}],key:("vertical_" + index),class:[
        {'active': stage.active, 'disabled': !stage.active},
        _vm.type,
        { 'active-line': _vm.timeline[index + 1] && _vm.timeline[index + 1].active}
      ],attrs:{"title":stage.title,"subtitle":stage.subtitle,"time":stage.date,"colors":stage,"iconClass":stage.iconClass,"variant":!_vm.styleColor ? _vm.color || stage.color || 'light' : 'personalized',"icon":_vm.verifyTypeTimeline(stage, index) ? (_vm.icon && !stage.icon ? (stage.active ? _vm.icon : 'void') : stage.icon) : 'void'}})}),1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chronology-progress"},[_c('div',{staticClass:"progress-bar"})])}]

export { render, staticRenderFns }