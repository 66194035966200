export const getUserToSave = (data) => {
  return {
    id: data.id,
    avatar: data.avatar,
    fullName: data.fullName,
    first_name: data.first_name,
    last_name: data.last_name,
    date_format: data.date_format,
    timezone: data.timezone,
    email: data.email,
    organization: data.organization,
    shipper: data.shipper || {},
    country: data.country,
    remember: data.remember,
    role: data.role,
    token: data.token,
    permissions: data.permissions,
    special_permissions: data.legacy_data?.special_functions ?? data.special_permissions ?? [],
    global_scope: data.global_scope ?? {},
    dubai_organization_id: data.legacy_data?.dubai_organization_id || null,
    profile_type: data.legacy_data?.profile || null,
    special_profile: data.legacy_data?.special_profile || data?.special_profile,
    permissions_v2: data.permissions_v2,    
    feature_access: data.feature_access || null,
    user_access: {
      can_access_return: data.legacy_data?.can_access_return ?? data.user_access?.can_access_return ?? null,
      show_stimated_price: data.legacy_data?.show_stimated_price ?? data.user_access?.show_stimated_price ?? null,
      employee_comp_custom: data.legacy_data?.user_employee_customization ?? data.user_access?.employee_comp_custom ?? null,
      block_delete_deliveries: data.legacy_data?.block_delete_deliveries ?? data.user_access?.block_delete_deliveries ?? null,
      company_category_id: data.legacy_data?.employee?.company?.company_category_id ?? data.user_access?.company_category_id ?? null,
      show_chat: data.legacy_data?.show_chat ?? data.user_access?.show_chat
    },
    permission_views: data.permission_views,
    restriction_views: data.restriction_views,
    is_company_employee_marketplace: data.legacy_data?.is_company_employee_marketplace ?? data.is_company_employee_marketplace,
    firstLoginToFreemium: data.firstLoginToFreemium
  }
}