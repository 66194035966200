import { environment } from '@/environments/environment'
export default [
  { name: 'getCarriersList', url: `${environment.torontoApiUrl}/configurations`, method: 'get'},
  { name: 'getDetailCarrier', url: `${environment.torontoApiUrl}/configurations/{id_carrier}`, method: 'get'},
  { name: 'getDetailVersion', url: `${environment.torontoApiUrl}/configurations/{id_carrier}/versions/{id_version}`, method: 'get'},
  { name: 'updateDetailCarrier', url: `${environment.torontoApiUrl}/configurations/{id_carrier}`, method: 'POST'},
  {
    name: 'postFileMassiveCarriers', url: `${environment.torontoApiUrl}/configurations/bulk/excel`, method: 'post', headers: {
      'Content-Type': 'multipart/form-data'
    }
  }
]
