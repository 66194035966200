import Vue from 'vue'
import VueRouter from 'vue-router'
import Bugsnag from '@bugsnag/js' // Importar Bugsnag
import { environment } from '@/environments/environment'
import { canNavigate, matchRoleUrl } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import { loadLanguageAsync } from '@/libs/i18n'
import enviame from './routes/enviame'
import pathToRedirectByUser from '@/store/global/pathToRedirectByUser'

Vue.use(VueRouter)

const userData = getUserData()
const pathName = pathToRedirectByUser[userData?.role] ?? 'dashboard'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '', redirect: { name: environment.redirectViewHome ? environment.redirectViewHome : pathName } },
    { path: '/', redirect: { name: environment.redirectViewHome ? environment.redirectViewHome : pathName } },
    ...enviame,
    {
      path: '*',
      redirect: { name: 'error' }
    }
  ]
})

/**
   * redirectToPath se creo para manejar el redireccionado luego del login,
   * cuando este migrado todo el proyecto se debe mejorar,
   * ya que no sera más un iframe
   */
export let redirectToPath = null

// Manejo de errores en las rutas
router.onError(error => {
  // Notificar a Bugsnag
  Bugsnag.notify(error, event => {
    event.addMetadata('routeError', {
      message: 'Error durante la navegación',
      fromRoute: router.currentRoute.fullPath,
      errorMessage: error.message
    })
  })

  console.error('Error durante la navegación:', error) // Depuración

  // Redirigir a una página de error si es necesario
  router.push({ name: 'error' })
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    if (!isLoggedIn) {
      redirectToPath = to.path
      return next({ name: 'auth-login' })
    }
    return next({ name: 'forbidden' })
  }

  if (!!userData && userData.role === 'carrier' && to.fullPath !== '/403' && !matchRoleUrl(userData.role, to.fullPath)) next({ name: 'forbidden' })

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const homeRoute = getHomeRouteForLoggedInUser(userData ? userData.role : null, from)
    if (homeRoute) {
      return next(homeRoute)
    } else {
      return next(false)
    }
  }
  loadLanguageAsync(to.name)
  return next()
})

router.afterEach((to, from, next) => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading && (!from || !from.name || from.name.indexOf('auth-') === -1)) {
    appLoading.style.display = 'none'
  }
})

export default router
