import BaseServices from '@/store/services/index'
const baseService = new BaseServices()

export default {
  state: {
    shippers: [],
    shipper: {},
    shippersByOrganization: [],
    shippersByOrganizationSimplified: [],
    shippersByOrganizationSimplifiedQueryParams: [],
    authorizedCarriersShippers: [],
    shipperRules: null,
    organizationCarrierContract: [],
    accountsFlex: {},
    warehousesPucon: []
  },
  getters: {
    getShippers: state => state.shippers,
    getShipper: state => state.shipper,
    getShippersByOrganization: state => state.shippersByOrganization,
    getShippersByOrganizationSimplified: state => state.shippersByOrganizationSimplified,
    getShippersByOrganizationSimplifiedQueryParams: state => state.shippersByOrganizationSimplifiedQueryParams,
    getAuthorizedCarriersShippers: state => state.authorizedCarriersShippers,
    getShipperRules: state => state.shipperRules,
    getOrganizationCarrierContract: state => state.organizationCarrierContract,
    getAccountsFlex: state => state.accountsFlex,
    getWarehousesApiPlatformPucon: state => state.warehousesPucon
  },
  mutations: {
    setShippersByOrganization(state, val) {
      state.shippersByOrganization = val.rows.map(el => ({ ...el, text: el.name }))
    },
    setShippersByOrganizationSimplified(state, val) {
      state.shippersByOrganizationSimplified = val.rows.map(el => ({ ...el, text: el.name1 }))
    },
    setShippersByOrganizationSimplifiedQueryParams(state, val) {
      state.shippersByOrganizationSimplifiedQueryParams = val.rows.map(el => ({ ...el, text: el.name }))
    },
    setShippers(state, val) {
      state.shippers = {
        rows: val.rows.map(shipper => {
          return {
            ...shipper,
            text: shipper.name1,
            organization_name: shipper.organization.name,
            country_name: shipper.country.name
          }
        }),
        meta: val.meta || {},
        total: val.total
      }
    },
    setShipper(state, val) {
      state.shipper = {
        ...val,
        type: 'shipper',
        business_activity: val.extra_fields?.business_activity,
        webpage: val.extra_fields?.webpage,
        contact_dni: val.address?.contact?.dni,
        contact_name: val.address?.contact?.name,
        contact_email: val.address?.contact?.email,
        contact_phone: val.address?.contact?.phone,
        zip_code: val.address?.address?.zip_code,
        street_name: val.address?.address?.street_name,
        street_number: val.address?.address?.street_number,
        information: val.address?.address?.information,
        autocomplete: val.address?.address?.location?.lat ? {
          id: val.address.id,
          text: `${val.address?.address?.street_name}, ${val.address?.address?.street_number}`,
          name: `${val.address?.address?.street_name}, ${val.address?.address?.street_number}`,
          formatted_address: val.address?.address?.formatted_address,
          full_address: val.address?.address?.full_address,
          geocode_api: val.address?.address?.geocode_api,
          geocode_val: val.address?.address?.geocode_val || {},
          street_name: val.address?.address?.street_name,
          street_number: val.address?.address?.street_number
        } : null,
        level1Text: val.address?.address?.level1 ? val.address?.address?.level1 : '',
        level2Text: val.address?.address?.level2 ? val.address?.address?.level2 : '',
        level3Text: val.address?.address?.level3 ? val.address?.address?.level3 : '',
        level4Text: val.address?.address?.level4 ? val.address?.address?.level4 : '',
        level1: val.address?.address?.level1 ? val.address?.address?.level1 : '',
        level2: val.address?.address?.level2 ? val.address?.address?.level2 : '',
        level3: val.address?.address?.level3 ? val.address?.address?.level3 : '',
        level4: val.address?.address?.level4 ? val.address?.address?.level4 : ''
      }
      if (val.address?.address?.location?.lat) {
        state.shipper.autocomplete.location = val.address?.address?.location
      }
    },
    setAuthorizedCarriersShippers(state, val) {
      state.authorizedCarriersShippers = val.data.map(shipper => (
        {
          ...shipper,
          carrier_name: shipper.carrier.name,
          service_name: shipper.services.map(service => service.name).join(' - ')
        }
      ))
    },
    setShipperRules(state, val) {
      state.shipperRules = val.data
    },
    setOrganizationCarrierContract(state, val) {
      state.organizationCarrierContract = val.data
    },
    setAccountsFlex(state, val) {
      state.accountsFlex = { data: val.data, pagination: val.pagination }
    },
    setWarehousesApiPlatformPucon(state, val) {
      state.warehousesPucon = val
    }
  },
  actions: {
    fetchShipper({ commit, getters, dispatch }, data) {
      commit('setLoading', { name: 'getShipper', status: true })
      baseService.callService('getShipper', null, data)
        .then(response => {
          commit('setLoading', { name: 'getShipper', status: false })
          const currentShipper = {
            ...response.data
          }
          //currentShipper.organization = currentShipper.organization_id = getters.getOrganizations.rows.filter(el => el.id === response.data.organization.id)[0]
          commit('setShipper', currentShipper)
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
    },
    fetchAuthorizedCarriersShippers({ commit }, { queryParams, params } = {}) {
      baseService.callService('getAuthorizedCarriersShippers', queryParams, params)
        .then(response => {
          const currentAuthorizedCarrier = response.data.data
          commit('setAuthorizedCarriersShippers', currentAuthorizedCarrier)
        })
        .catch(err => {
          console.error(err)
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
    },
    fetchCreateAccount({ commit, dispatch }, service = {}) {
      const { name, queryParams, params, headers } = service
      baseService.callService(name, queryParams, params, headers).then(response => dispatch('generateResponse', { service, response })
      ).catch(err => {
        console.error(err)
        commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
      })
    }
  }
}
