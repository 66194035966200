import BaseServices from '@/store/services/index'
const baseService = new BaseServices()

export default {
  state: {
    getCountriesRatesQuoter: [],
    getPlacesRatesQuoter: [],
    getCarriersRatesQuoter: [],
    getServicesNY: [],
    getCategoryServiceRatesQuoter: [],
    getRatesRatesQuoter: [],
    getServiceRatesQuoter: [],
    getPricesRatesQuoter: [],
    getCostsRatesQuoter: [],
    getCarriersForCountry: [],
    getCarriersOriginsModal: [],
    getOriginsNY: [],
    getDestinationsNY: [],
    getOriginsToDestinationNY: [],
    getDestinationToOriginsNY: [],
    getChargesRatesQuoter: [],
    getPreferentialRates: [],
    getMultipleRates: [],
    getInternationalCarriers: [],
    getInternationalServices: [],
    getInternationalOriginsOrDestiantions: []
  },

  getters: {
    getCountriesRatesQuoter: (state) => state.getCountriesRatesQuoter,
    getPlacesRatesQuoter: (state) => state.getPlacesRatesQuoter,
    getCarriersRatesQuoter: (state) => state.getCarriersRatesQuoter,
    getServicesNY: (state) => state.getServicesNY,
    getCategoryServiceRatesQuoter: (state) => state.getCategoryServiceRatesQuoter,
    getRatesRatesQuoter: (state) => state.getRatesRatesQuoter,
    getServiceRatesQuoter: (state) => state.getServiceRatesQuoter,
    getPricesRatesQuoter: (state) => state.getPricesRatesQuoter,
    getCostsRatesQuoter: (state) => state.getCostsRatesQuoter,
    getCarriersForCountry: (state) => state.getCarriersForCountry,
    getCarriersOriginsModal: (state) => state.getCarriersOriginsModal,
    getOriginsNY: (state) => state.getOriginsNY,
    getDestinationsNY: (state) => state.getDestinationsNY,
    getOriginsToDestinationNY: (state) => state.getOriginsToDestinationNY,
    getDestinationToOriginsNY: (state) => state.getDestinationToOriginsNY,
    getChargesRatesQuoter: (state) => state.getChargesRatesQuoter,
    getPreferentialRates: (state) => state.getPreferentialRates,
    getMultipleRates: (state) => state.getMultipleRates,
    getInternationalCarriers: (state) => state.getInternationalCarriers,
    getInternationalServices: (state) => state.getInternationalServices,
    getInternationalOriginsOrDestiantions: (state) => state.getInternationalOriginsOrDestiantions
  },

  mutations: {
    setCountriesRatesQuoter(state, val) {
      state.getCountriesRatesQuoter = {
        rows: val.rows.map((el) => ({ ...el, text: el.name }))
      }
    },
    setPlacesRatesQuoter(state, val) {
      if (val) {
        state.getPlacesRatesQuoter = {
          rows: val.places.map((el) => ({ ...el, text: el.full_name, valid: true }))
        }
      } else {
        state.getPlacesRatesQuoter = {
          rows: []
        }
      }
    },
    setCarriersRatesQuoter(state, val) {
      if (val) {
        state.getCarriersRatesQuoter = {
          rows: val.data.map((el) => ({ ...el, text: el.name }))
        }
      } else {
        state.getCarriersRatesQuoter = {
          rows: []
        }
      }
    },
    setServicesNY(state, val) {
      if (val) {
        state.getServicesNY = {
          rows: val.data.map((el) => ({ ...el, text: el.name }))
        }
      } else {
        state.getServicesNY = {
          rows: []
        }
      }
    },
    setCategoryServiceRatesQuoter(state, val) {
      if (val) {
        state.getCategoryServiceRatesQuoter = {
          rows: val.data.map((el) => ({ ...el, id: el.id, text: el.name, code: el.type }))
        }
      } else {
        state.getCategoryServiceRatesQuoter = {
          rows: []
        }
      }
    },
    setRatesRatesQuoter(state, val) {
      if (val) {
        state.getRatesRatesQuoter = {
          rows: val.data
        }
      } else {
        state.getRatesRatesQuoter = {
          rows: []
        }
      }
    },
    setServiceRatesQuoter(state, val) {
      if (val) {
        state.getServiceRatesQuoter = {
          rows: val.data.map((el) => ({ ...el, text: el.name, carrier_id: val.meta?.params?.carrier_id }))
        }
      } else {
        state.getServiceRatesQuoter = {
          rows: []
        }
      }
    },
    setPricesRatesQuoter(state, val) {
      if (val) {
        state.getPricesRatesQuoter = {
          rows: val.data || val.destinationPrices || [],
          meta: val.meta
        }
      } else {
        state.getPricesRatesQuoter = {
          rows: []
        }
      }
    },
    setCostsRatesQuoter(state, val) {
      if (val) {
        state.getCostsRatesQuoter = {
          rows: val.data || val.destinationCosts || [],
          meta: val.meta
        }
      } else {
        state.getCostsRatesQuoter = {
          rows: []
        }
      }
    },
    setCarriersForCountry(state, val) {
      if (val) {
        state.getCarriersForCountry = {
          rows: val.data.map((el) => ({ ...el, text: el.name }))
        }
      } else {
        state.getCarriersForCountry = {
          rows: []
        }
      }
    },
    setCarriersOriginsModal(state, val) {
      if (val) {
        state.getCarriersOriginsModal = {
          rows: val.data.map((el) => ({ ...el, text: el.name }))
        }
      } else {
        state.getCarriersOriginsModal = {
          rows: []
        }
      }
    },
    setOriginsNY(state, val) {
      if (val) {
        state.getOriginsNY = {
          rows: val.data.map((el) => ({ ...el, text: el.name })),
          meta: val.meta
        }
      } else {
        state.getOriginsNY = {
          rows: []
        }
      }
    },
    setDestinationsNY(state, val) {
      if (val) {
        state.getDestinationsNY = {
          rows: val.data.map((el) => ({ ...el, text: el.name })),
          meta: val.meta
        }
      } else {
        state.getDestinationsNY = {
          rows: []
        }
      }
    },
    setOriginsToDestinationNY(state, val) {
      if (val) {
        state.getOriginsToDestinationNY = {
          rows: val.data.map((el) => ({ ...el, text: el.name })),
          meta: val.meta
        }
      } else {
        state.getOriginsToDestinationNY = {
          rows: []
        }
      }
    },
    setDestinationToOriginsNY(state, val) {
      if (val) {
        state.getDestinationToOriginsNY = {
          rows: val.data.map((el) => ({ ...el, text: el.name })),
          meta: val.meta
        }
      } else {
        state.getDestinationToOriginsNY = {
          rows: []
        }
      }
    },
    setChargesRatesQuoter(state, val) {
      if (val) {
        state.getChargesRatesQuoter = {
          rows: val.rows,
          meta: val.meta
        }
      } else {
        state.getChargesRatesQuoter = {
          rows: []
        }
      }
    },
    setPreferentialRates(state, val) {
      if (val) {
        state.getPreferentialRates = {
          rows: val.data.map((el) => ({ ...el, text: el.name })),
          count: val.count
        }
      } else {
        state.getPreferentialRates = {
          rows: []
        }
      }
    },
    setMultipleRates(state, val) {
      if (val) {
        state.getMultipleRates = {
          rows: val.data.map((el) => ({ ...el, text: el.name }))
        }
      } else {
        state.getMultipleRates = {
          rows: []
        }
      }
    },
    setInternationalCarriers(state, val) {
      if (val) {
        state.getInternationalCarriers = {
          rows: val.data.map((el) => ({ ...el, text: el.name }))
        }
      } else {
        state.getInternationalCarriers = {
          rows: []
        }
      }
    },
    setInternationalServices(state, val) {
      if (val) {
        state.getInternationalServices = {
          rows: val.data.map((el) => ({ ...el, text: el.name })),
          meta: val.meta
        }
      } else {
        state.getInternationalServices = {
          rows: []
        }
      }
    },
    setInternationalOriginsOrDestiantions(state, val) {
      if (val) {
        state.getInternationalOriginsOrDestiantions = {
          rows: val.data.map((el) => {
            let code = ''
            switch (el.country_name) {
            case 'México':
              code = 'MX'
              break
            case 'Estados Unidos': 
              code = 'US'
              break
            case 'Colombia':
              code = 'CO'
              break
            case 'Chile':
              code = 'CL'
              break
            case 'Perú':
              code = 'PE'
              break
            case 'Argentina':
              code = 'AR'
              break
            }
            return { ...el, text: el.name, country: {code} }
          }),
          meta: val.meta
        }
      } else {
        state.getInternationalOriginsOrDestiantions = {
          rows: []
        }
      }
    }
  },
  actions: {
    getFiles({ commit }, { name, params, queryParams = {}, onSuccess = null }) {
      commit('setLoading', { name, status: true })
      baseService
        .callService(name, queryParams, params, { fullResponse: true })
        .then(({ data }) => {
          let fileName = 'file.xls'
          if (name === 'getFileRatesQuoter') {
            fileName = `${
              params.pricesOrCosts === 'costs' ? 'costo-' : ''
            }${params.carrier_name.toLowerCase()}-${params.places_name.toLowerCase()}-${params.service_name}.xlsx`
          } else if (name === 'getFileComparator') {
            fileName = `Comparación-${params.origin}-${params.category}.xlsx`
          } else if (name === 'getFileOrigins') {
            fileName = `Orígenes-${params.country_name}-${params.carrier_name}-${params.service_name}.xlsx`
          }
          try {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(data, fileName)
            } else {
              const objectUrl = URL.createObjectURL(data)
              const link = document.createElement('a')
              link.href = objectUrl
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
            }
          } catch (exc) {
            commit('setMessage', { text: 'msg-problema-cargar-datos', code: exc, type: 'alert' })
          }
          if (onSuccess) onSuccess()
        })
        .catch((err) => {
          commit('setMessage', { text: 'msg-problema-cargar-datos', code: err, type: 'alert' })
        })
        .finally(() => {
          commit('setLoading', { name, status: false })
        })
    }
  }
}
