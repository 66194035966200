import { environment } from '@/environments/environment'
export default [
  { name: 'getCountriesRatesQuoter', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries`, method: 'get', notCancel: true},
  { name: 'getPlacesRatesQuoter', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/places/full-level-search`, method: 'get', notCancel: true},
  { name: 'getCarriersRatesQuoter', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries/{country_id}/places/{place_id}/carriers`, method: 'get', notCancel: true},
  { name: 'getServicesNY', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries/{country_id}/carriers/{carrier_id}/services`, method: 'get', notCancel: true},
  { name: 'getCategoryServiceRatesQuoter', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries/{country_id}/places/{place_id}/carriers/{carrier_id}/service-types`, method: 'get', notCancel: true},
  { name: 'getRatesRatesQuoter', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates`, method: 'get' },
  { name: 'getServiceRatesQuoter', url: `${environment.newyorkApiUrl}/v3/coverage/in-current-coverage/countries/{country_id}/places/{place_id}/carriers/{carrier_id}/service-types/{service_type_id}/services`, method: 'get', notCancel: true},
  { name: 'getPricesRatesQuoter', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/from-places/{place_id}/prices`, method: 'get', notCancel: true},
  { name: 'getCostsRatesQuoter', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/from-places/{place_id}/costs`, method: 'get', notCancel: true},
  { name: 'getFileRatesQuoter', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/from-places/{place_id}/{pricesOrCosts}/generate-file`, method: 'get', notCancel: true, responseType: 'blob' },
  { name: 'getOriginsNY', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/origins`, method: 'get', notCancel: true },
  { name: 'getInternationalOriginsOrDestiantions', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/international/{search_by}`, method: 'get', notCancel: true },
  { name: 'getDestinationsNY', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/destinations`, method: 'get', notCancel: true },
  { name: 'getOriginsToDestinationNY', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/destinations/{origin_or_destination_id}/origins`, method: 'get', notCancel: true },
  { name: 'getDestinationToOriginsNY', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/origins/{origin_or_destination_id}/destinations`, method: 'get', notCancel: true },
  { name: 'putShowOrHidden', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/{originsOrDestinations}/{showOrHide}`, method: 'put'},
  { name: 'putShowOrHiddenMassive', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/{type}/massive/{action}`, method: 'put'},
  { name: 'putShowOrHiddenOrigenOrDestination', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/{originsOrDestinations}/{origin_or_destination_id}/{showOrHide}`, method: 'put'},
  { name: 'getChargesRatesQuoter', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/route-charges`, method: 'get', notCancel: true},
  { name: 'getFileOrigins', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/{originsOrDestinations}/generate-file`, method: 'get', notCancel: true, responseType: 'blob'},
  { name: 'getPreferentialRates', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/preferential-rates`, method: 'get', notCancel: true},
  { name: 'getMultipleRates', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/services/rates`, method: 'get' },
  { name: 'postMultipleFiles', url: `${environment.newyorkApiUrl}/v3/coverage/file-dispatcher/{pricesOrCosts}`, method: 'post', headers: {'Content-Type': 'application/json'}},
  { name: 'postFutureCoveragesNY', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/import-future-coverages`, method: 'post', headers: {'Content-Type': 'multipart/form-data'}},
  { name: 'putUpdateCoveragesNY', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/update-coverages`, method: 'put', headers: {'Content-Type': 'multipart/form-data'}},
  { name: 'postFreightChargesNY', url: `${environment.newyorkApiUrl}/v2/coverages/carriers/{carrier_id}/services/{service_id}/import-freight-charges`, method: 'post', headers: {'Content-Type': 'multipart/form-data'}},
  { name: 'putUpdateRateCoverageNY', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/update-coverages`, method: 'put', headers: {'Content-Type': 'multipart/form-data'}},
  { name: 'getHistoryFileRates', url: `${environment.newyorkApiUrl}/v3/coverage/carriers/{carrier_id}/services/{service_id}/rates/{rate_id}/history-file-rates`, method: 'get' },
  { name: 'getHistoryFileInternationalRates', url: `${environment.newyorkApiUrl}/v3/coverage/international-rates/{rate_id}/history/files`, method: 'get' },
  { name: 'postCopyCovergaes', url: `${environment.newyorkApiUrl}/v3/coverage/rates/{rate_id}/copy-coverages`, method: 'post', headers: {'Content-Type': 'application/json'}},
  { name: 'postCopyBigCovergaes', url: `${environment.newyorkApiUrl}/v3/coverage/rates/{rate_id}/copy-mx-coverages`, method: 'post', headers: {'Content-Type': 'application/json'}},
  { name: 'getPendingActivities', url: `${environment.newyorkApiUrl}/v3/coverage/get-pending-activities`, method: 'get', notCancel: true },
  { name: 'getActivityRates', url: `${environment.newyorkApiUrl}/v3/coverage/countries/{country_id}/activity-rates`, method: 'get', notCancel: true },
  { name: 'putApproveRejectRateActivities', url: `${environment.newyorkApiUrl}/v3/coverage/charged-rate-activities/{charged_rate_activiyty_id}`, method: 'put' }
]
