<template>
    <div :id="element">
      <slot><div v-html="content"></div></slot>
    </div>
  </template>
  
<script>
const $ = require('jquery')
import 'summernote/dist/summernote-lite.min.css'
import 'summernote/dist/summernote-lite.min'

export default {
  name: 'Summernote',
  data () {
    return {
      element: `summernote_${new Date().getTime()}`
    }
  },
  props: {
    config: {
      type: Object
    },
    ready: {
      type: Function
    },
    content: {
      type: String
    }
  },
  mounted () {
    this.$nextTick(() => {
      const defaultConfig = {
        lang: 'es',
        toolbar: [
          ['style', ['style']],
          ['font', ['bold', 'italic', 'underline', 'strikethrough']],
          ['fontsize', ['fontsize', '2']],
          ['fontname', ['fontname']],
          ['link'],
          ['picture'],
          ['video'],
          ['forecolor'],
          ['para', ['ul', 'paragraph']],
          ['codeview']
        ],
        placeholder: 'Escribe aquí..',
        height: 400,
        width: '100%',
        tabsize: 2,
        focus: true,
        fontSizes: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '20', '24', '28', '32', '36', '40', '50', '60'],
        fontNames: ['Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Helvetica', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Montserrat'],
        callbacks: {
          onInit: () => {
            this.$emit('ready', this)
          },
          onChange: contents => {
            this.$emit('update:content', contents)
          },
          onEnter: () => {
            this.$emit('enter')
          },
          onFocus: () => {
            this.$emit('focus')
          },
          onBlur: () => {
            this.$emit('blur')
          },
          onKeyup: e => {
            this.$emit('keyup', e)
          },
          onKeydown: e => {
            this.$emit('keydown', e)
          },
          onPaste: e => {
            this.$emit('paste', e)
          },
          onImageUpload: files =>  {
            const maxSize = 1048576
            const file = files[0]
            
            if (file.size > maxSize) {
              this.$alert(this.$t('La imagen es demasiado grande. El tamaño máximo permitido es de 1 MB.'))
            } else {
              const vueContext = this
              const reader = new FileReader()
              reader.onloadend = function () {
                $(`#${  vueContext.element}`).summernote('insertImage', reader.result)
              }
              reader.readAsDataURL(file)
            }
          }
        }
      }
      const config = Object.assign({}, defaultConfig, this.config)
      $(`#${  this.element}`).summernote(config)
    })
  },
  methods: {
    summernote (...args) {
      $(`#${  this.element}`).summernote(...args)
    },
    ui () {
      return $.summernote.ui
    }
  }
}
</script>
<style>
    .summernote-img {
    max-width: 100%;
    }
    .note-modal-backdrop {
    z-index: 1031 !important;
    }
    .note-modal-footer {
    height: 60px !important;
    }
</style>