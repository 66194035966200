<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view/>
    </component>
    <scroll-to-top v-if="enableScrollToTop" />
    <modular-permissions/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import Cookies from 'js-cookie'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import { getUserToSave } from './@core/utils/parsedUser'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutCarrier = () => import('@/layouts/carrier/LayoutCarrier.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    LayoutCarrier,

    ScrollToTop
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      else if (this.checkLayoutCarrier) return 'layout-carrier'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    checkLayoutCarrier() {
      return this.$store.state.login.iniUser.role === 'carrier' || this.$route.meta.layout === 'carrier'
    },
    ...mapGetters({
      userData: 'getUserData',
      userAlerts: 'getUserAlerts',
      iniUser: 'getIniUser',
      abilityUser: 'getAbilityUser',
      message: 'getMessage'
    })
  },
  watch: {
    message: {
      handler () {
        this[`$${this.message.type || 'alert'}`](this.$t(this.message.text, {code: this.message.code}))
      },
      deep: true
    },
    userAlerts (curr) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {...curr}
      })
    },
    abilityUser (curr, prev) {
      this.$ability.update(curr)
    },
    iniUser: {
      handler(curr, prev) {
        if (!!curr) {
          this.$i18n.locale = 'cl'
          this.$session.start()
          const userToSave = this.getUserToSave(curr)
          const objExpires = { expires: 1000, sameSite: 'None' }// !userToSave.remember ? { expires: ((1 / 24) * 8) } : { expires: 30 }
          // useJwt.setCookie('session', window.btoa(JSON.stringify(userToSave)), (!userToSave.remember ? ((1 / 24) * 8) : 30))
          Cookies.set('session', window.btoa(JSON.stringify(userToSave)), objExpires)
          Cookies.set('session_id', `${curr.id}##${curr.token}`, objExpires)
          localStorage.setItem('session_id', `${curr.id}##${curr.token}`)
          Cookies.set('permissions', window.btoa(JSON.stringify(curr.permissions)), objExpires)
          this.$session.set('cas_user', userToSave)
          this.$session.set('permissions', curr.permissions)
          this.$store.dispatch('fetchSession', userToSave)
          this.$ability.update(curr.ability)
          if (curr.from !== 'app' && curr.from !== 'app-render' && curr.from !== 'external') {
            const currentRoute = this.$router.currentRoute
            if (currentRoute) {
              const homeRoute = getHomeRouteForLoggedInUser(curr.role, currentRoute, curr)
              if (homeRoute) {
                this.$router.replace(homeRoute)
              }
            }
          }
        }
      },
      deep: true
    },
    userData : {
      handler (curr, prev) {
        const userToSave = this.getUserToSave(curr)
        this.$session.set('cas_user', userToSave)
        this.$session.set('permissions', curr.permissions)
        this.$store.dispatch('fetchSession', userToSave)
        const objExpires = { expires: 1000, sameSite: 'None' }// !userToSave.remember ? { expires: ((1 / 24) * 8) } : { expires: 30 }
        // useJwt.setCookie('session', window.btoa(JSON.stringify(userToSave)), (!userToSave.remember ? ((1 / 24) * 8) : 30))
        Cookies.set('session', window.btoa(JSON.stringify(userToSave)), objExpires)
        Cookies.set('session_id', `${curr.id}##${curr.token}`, objExpires)

        localStorage.setItem('session_id', `${curr.id}##${curr.token}`)

        Cookies.set('permissions', window.btoa(JSON.stringify(curr.permissions)), objExpires)
        this.$ability.update(curr.ability)
        // const objExpires = !this.$session.get('cas_user').remember ? { expires: ((1 / 24) * 8) } : { expires: 30 }
        // useJwt.setCookie('session', window.btoa(JSON.stringify(curr)), (!curr.remember ? ((1 / 24) * 8) : 30))
      },
      deep: true
    }
  },
  beforeCreate() {
    if (!this.$session.get('cas_user') && localStorage.getItem('session_id')) {
      if (!this.$router.history?.current?.meta?.external) {
        const cookie = localStorage.getItem('session_id').split('##')
        if (!cookie[1]) this.$store.dispatch('fetchLogout', this)
        this.$store.dispatch('fetchUserData', {id: cookie[0], token: cookie[1], from: 'app-render'})
      }
    }
    else if (this.$session.get('cas_user')) {
      if (!this.$router.history?.current?.meta?.external) {
        if (localStorage.getItem('custom-token') && localStorage.getItem('userData')) {
          const userTosave = getUserToSave(JSON.parse(localStorage.getItem('userData')))
          this.$session.set('cas_user', userTosave)
        }
        if (!this.$session.get('cas_user').permissions) {
          this.$store.dispatch('fetchUserData', {...this.$session.get('cas_user'), from: 'app'})
        } else {
          this.$store.dispatch('fetchPermissions', { user: {...this.$session.get('cas_user'), from: 'app'} })
        }
      }
    } else {
      Cookies.remove('session')
      Cookies.remove('session_id')
      Cookies.remove('permissions')

      localStorage.removeItem('session_id')
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      localStorage.removeItem('userData')
      this.$session.destroy()

      this.$ability.update(initialAbility)

      localStorage.removeItem('accessToken')
    }

    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade'
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop
    }
  },
  methods: {
    getUserToSave (data) {
      return getUserToSave(data)
    }
  }
}
</script>
