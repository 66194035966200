export default {
  state: {
    folders: []
  },

  getters: {
    getFolders: state => state.folders
  },

  mutations: {
    setFolders(state, val) {
      state.folders = {
        rows: val.rows.map(folder => {
          return {
            ...folder,
            folder_name: folder.name,
            organization_name: folder.organization?.name
          }  
        }),
        total: val.total
      }
    }
  },

  actions: { }
}