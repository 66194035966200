<template>
    <span class="custom-alert-field" :class="text? 'has-text' : ''">
        <div class="con-text-validation vs-input--text-validation-span v-enter-to"
        :class="`span-text-validation-${type || '_'}`">
            <span class="span-text-validation invalid-feedback">{{text || '&nbsp;'}}</span>
        </div>
    </span>
</template>
<script>
export default {
  name: 'custon-alert-field',
  props: ['text', 'type']
}
</script>
<style lang="scss">
.custom-alert-field{
    transition: all 0.3s ease;
    &:not(.has-text){
        color: transparent !important;
        > .con-text-validation > .span-text-validation{
            max-height: 0 !important;
            min-height: 0 !important;
            padding: 0px 4px !important;
            padding-bottom: 0px !important;
        }
    }
}
.vs-con-textarea + span.custom-alert-field  { 
    top: -16px;
    position: relative;
    height: 0px;
    display: block;
}
.vx-row > .custom-alert-field.new-line{
    left: 14px;
}
.new-line {
    width: 100%;
    height: 0px;
    position: relative;
    display: block;
}
// .con-text-validation .span-text-validation{
//     min-height: 22px;
// }
</style>