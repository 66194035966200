import { environment } from '@/environments/environment'
export default [
  { name: 'getPudos', url: `${environment.platformUrl}/apihub/pudos`, method: 'get', useHubToken: true},
  { name: 'getWarehousesByCompany', url: `${environment.platformUrl}/apihub/ajax/companies/{company_id}/warehouses`, method: 'get', useHubToken: true},
  { name: 'getCarriersByCompany', url: `${environment.platformUrl}/apihub/companies/{company_id}/carriers `, method: 'get', useHubToken: true},
  { name: 'getShippersByOrganization', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers`, method: 'get'},
  
  { name: 'postTicketReturn', url: `${environment.torontoApiUrl}/return-tickets`, method: 'post'},
  { name: 'getReturnTickets', url: `${environment.torontoApiUrl}/return-tickets/{id}`, method: 'get'},
  { name: 'getTicketTypesByReturn', url: `${environment.torontoApiUrl}/delivery-statuses/{code}/return-ticket-types`, method: 'get'},
  { name: 'postMessageTicketReturn', url: `${environment.torontoApiUrl}/return-tickets/{id}/messages`, method: 'post'},
  { name: 'getTicketStatuses', url: `${environment.sfApiUrl}/ticket-statuses`, method: 'get' },
  { name: 'updateReturnTicket', url: `${environment.torontoApiUrl}/return-tickets/{id}`, method: 'put' },

  // { name: 'getReturn', url: `${environment.platformUrl}/apihub/return-delivery/{id}`, method: 'get', useHubToken: true},
  // { name: 'createReturn', url: `${environment.platformUrl}/apihub/return-deliveries-create`, method: 'post', useHubToken: true},
  // { name: 'updateReturn', url: `${environment.platformUrl}/apihub/return-deliveries-update`, method: 'post', useHubToken: true},
  { name: 'getReinyectedReturnDeliv', url: `${environment.platformUrl}/return-deliveries-dispatch/{company_id}/{id}/{token}`, method: 'get' },

  //MIGRACION
  //DOC: https://enviame-latam.atlassian.net/wiki/spaces/RT/pages/1371176979/Return-deliveries
  { name: 'getReturn', url: `${environment.beijingApiUrl}/deliveries/{id}`, method: 'get'},
  { name: 'postReturn', url: `${environment.beijingApiUrl}/shippers/{shipper_id}/deliveries`, method: 'post', hideAlert: true },
  { name: 'updateReturn', url: `${environment.beijingApiUrl}/shippers/{shipper_id}/deliveries/{id}`, method: 'put', hideAlert: true },
  { name: 'getDeliveriesReturn', url: `${environment.beijingApiUrl}/deliveries`, method: 'get' },
  { name: 'getDeliveryStatusesFull',  url: `${environment.apiPlatform}/v1/statuses`, method: 'get' },
  { name: 'getCompaniesPlatform', url: `${environment.apiPlatform}/v1/box-companies`, method: 'get' },
  { name: 'getCarriersPlatform', url: `${environment.apiPlatform}/v1/carriers-specials/companies/{company_id}`, method: 'get', notCancel: true },
  { name: 'getCarriersByCountriesPlatform', url: `${environment.apiPlatform}/v1/carriers/country/{country_id}`, method: 'get' },
  { name: 'getServiceByCarrier', url: `${environment.apiPlatform}/v1/carriers/{carrier_id}/services`, method: 'get' },
  { name: 'getDeliveriesReturnReport', url: `${environment.apiPlatform}/v1/return-deliveries-reports`, method: 'get' },
  { name: 'getDeliveriesReturnReportURL', url: `${environment.apiPlatform}/v1/return-deliveries-export`, method: 'get', notCancel: true, responseType: 'blob' },
  { name: 'getPlacesByCountry', url: `${environment.apiPlatform}/v1/places/{country_id}`, method: 'get' },
  { name: 'deliveriesDispatch', url: `${environment.beijingApiUrl}/shippers/{shipper_id}/deliveries/{delivery_id}/dispatches`, method: 'post' },
  { name: 'postPrintReturns', url: `${environment.apiPlatform}/v1/print-returns`, method: 'post' },

  { name: 'getWarehousesFlash', url: `${environment.beijingApiUrl}/filters/warehouses`, method: 'get'},
  { name: 'getCarriersReturns', url: `${environment.beijingApiUrl}/filters/carriers`, method: 'get'},
  { name: 'getPudosFlash', url: `${environment.beijingApiUrl}/filters/pudos`, method: 'get'},
  { name: 'getAgenciesFlash', url: `${environment.beijingApiUrl}/filters/agencies`, method: 'get'},
  { name: 'updatePackageReturn', url: `${environment.beijingApiUrl}/shippers/{shipper_id}/deliveries/{delivery_id}/transform-to-multipackage`, method: 'post'}
]