import { environment } from '@/environments/environment'
export default [
  { name: 'getPudosForType', url: `${environment.dubaiApiUrl}/pudos`, method: 'get' },
  { name: 'getCarriersName', url: `${environment.dubaiApiUrl}/carriers-with-pudos`, method: 'get', notCancel: true},
  { name: 'postPudo', url: `${environment.dubaiApiUrl}/pudos`, method: 'post', notCancel: true},
  { name: 'postCreateEmployeePudo', url: `${environment.dubaiApiUrl}/users`, method: 'post'},
  { name: 'putCreateEmployeePudo', url: `${environment.dubaiApiUrl}/users`, method: 'put'},
  { name: 'postPudoMassive', url: `${environment.dubaiApiUrl}/upload-excel`, method: 'post'},
  { name: 'getCarriersPudos', url: `${environment.tokyoApiUrl}/v1/carriers/carriers/filter?limit=250&page=1&simplify=true`, method: 'get' },
  { name: 'getGoogleAddress', url: `${environment.dubaiApiUrl}/google-addresses`, method: 'get', notCancel: true},   
  { name: 'downloadPudosExcel', url: `${environment.dubaiApiUrl}/excel/pudos`, method: 'get', notCancel: true, responseType: 'blob'},
  { name: 'deletePudo', url: `${environment.dubaiApiUrl}/pudos/{id}`, method: 'delete'},
  { name: 'getPudoId', url: `${environment.dubaiApiUrl}/pudos/{id}`, method: 'get' },
  { name: 'getOrganization', url: `${environment.dubaiApiUrl}/organizations/{id}`, method: 'get'},
  { name: 'putPudo', url: `${environment.dubaiApiUrl}/pudos/{id}`, method: 'put', notCancel: true},
  { name: 'putSchedules', url: `${environment.dubaiApiUrl}/pudos/{id}/schedules`, method: 'put', notCancel: true},
  { name: 'updateStatusDeliveries', url: `${environment.dubaiApiUrl}/pudos/deliveries/{delivery_id}/statuses`, method: 'post', notCancel: true},
  { name: 'getDeliveryStatusesPudo',  url: `${environment.dubaiApiUrl}/pudos/deliveries/status`, method: 'get' },
  { name: 'getDeliveriesPudos', url: `${environment.dubaiApiUrl}/pudos/deliveries`, method: 'get'},
  { name: 'downloadDeliveriesExcel', url: `${environment.dubaiApiUrl}/excel/deliveries `, method: 'get', notCancel: true, responseType: 'blob'},
  { name: 'getZipcode', url: `${environment.apiPlatform}/v1/zip-codes/{country_id}`, method: 'get' }
]