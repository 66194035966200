import { environment } from '@/environments/environment'
export default [

  { name: 'getAdvancedRule', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rules/{id}`, method: 'get'},
  { name: 'getAdvancedRules', url: `${environment.nippurApiUrl}/rules`, method: 'get', notCancel: true},
  { name: 'postAdvancedRule', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rules`, method: 'post'}, 
  { name: 'updateAdvancedRule', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rules/{id}`, method: 'put'},
  { name: 'deleteAdvancedRule', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rules/{id}`, method: 'delete', notCancel: true},
  { name: 'postSettingRule', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rule-settings`, method: 'post', notCancel: true},
  { name: 'updateSettingRule', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rule-settings/{id}`, method: 'put', notCancel: true},
  { name: 'deleteSettingRule', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rule-settings/{id}`, method: 'delete', notCancel: true},
  { name: 'getLocationCode', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/is-input`, method: 'get'},
  { name: 'postChangeValueCode', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rule-settings-input`, method: 'post', notCancel: true },
  { name: 'deleteChangeValueCode', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rule-settings-input`, method: 'delete', notCancel: true },
  
  { name: 'getAdvancedRuleByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rules/{id}`, method: 'get'},
  { name: 'getAdvancedRulesByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rules`, method: 'get', notCancel: true},
  { name: 'postAdvancedRuleByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rules`, method: 'post'},
  { name: 'updateAdvancedRuleByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rules/{id}`, method: 'put'},
  { name: 'deleteAdvancedRuleByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rules/{id}`, method: 'delete', notCancel: true},
  { name: 'postSettingRuleByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rule-settings`, method: 'post', notCancel: true},
  { name: 'updateSettingRuleByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rule-settings/{id}`, method: 'put', notCancel: true},
  { name: 'deleteSettingRuleByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rule-settings/{id}`, method: 'delete', notCancel: true},
  { name: 'getLocationCodeByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/is-input`, method: 'get'},
  { name: 'postChangeValueCodeByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rule-settings-input`, method: 'post', notCancel: true },
  { name: 'deleteChangeValueCodeByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rule-settings-input`, method: 'delete', notCancel: true },

  { name: 'postAdvancedRuleByOrganizationToShipper', url: `${environment.nippurApiUrl}/organizations/{organization_id}/shippers/{shipper_id}/rules`, method: 'post'},
  { name: 'updateAdvancedRuleByOrganizationToShipper', url: `${environment.nippurApiUrl}/organizations/{organization_id}/shippers/{shipper_id}/rules/{id}`, method: 'put'},
  { name: 'deleteAdvancedRuleByOrganizationToShipper', url: `${environment.nippurApiUrl}/organizations/{organization_id}/shippers/{shipper_id}/rules/{id}`, method: 'delete'}, 
  { name: 'postUploadFileLocation', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rule-settings-excel`, method: 'post', notCancel: true},
  { name: 'postUploadFileLocationByOrganization', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rule-settings-excel`, method: 'post', notCancel: true},

  // New version rules
  { name: 'postAdvancedRuleByOrganizationCondition', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rules/{rule_id}/conditions`, method: 'post'},
  { name: 'getAdvancedRuleByOrganizationCondition', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rules/{rule_id}/conditions/{condition_id}`, method: 'get'},
  { name: 'updateAdvancedRuleByOrganizationCondition', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rules/{rule_id}/conditions/{condition_id}`, method: 'put'},
  { name: 'deleteAdvancedRuleByOrganizationCondition', url: `${environment.nippurApiUrl}/organizations/{organization_id}/rules/{rule_id}/conditions/{condition_id}`, method: 'delete'},
  { name: 'postAdvancedRuleCondition', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rules/{rule_id}/conditions`, method: 'post'},
  { name: 'getAdvancedRuleCondition', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rules/{rule_id}/conditions/{condition_id}`, method: 'get'},
  { name: 'updateAdvancedRuleCondition', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rules/{rule_id}/conditions/{condition_id}`, method: 'put'},
  { name: 'deleteAdvancedRuleCondition', url: `${environment.nippurApiUrl}/shippers/{shipper_id}/rules/{rule_id}/conditions/{condition_id}`, method: 'delete'},
  { name: 'getRuleTypes', url: `${environment.nippurApiUrl}/rule-types`, method: 'get'}
]
