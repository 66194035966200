export default {
  //Creacion de envio
  admin: 'deliveries-create',
  user: 'deliveries-create',
  marketplace: 'deliveries-create',
  seller: 'deliveries-create',  
  ecommerce: 'deliveries-create',
  //carrier
  carrier: 'shipments-carriers',
  //?
  client: 'access-control',
  //Listado de envios
  pudo: 'deliveries-list',
  custom: 'deliveries-list'
}