import { environment } from '@/environments/environment'

export default [
  { name: 'getTickets', url: `${environment.torontoApiUrl}/tickets`, method: 'get' },
  { name: 'exportTickets', url: `${environment.torontoApiUrl}/delivery-tickets-export`, method: 'get' },
  {
    name: 'postFileRespondTickets', url: `${environment.torontoApiUrl}/delivery-tickets/bulk/excel`, method: 'post', headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  {
    name: 'postFileOT', url: `${environment.torontoApiUrl}/attachments-by-tracking-number`, method: 'post', headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  { name: 'getOrganization', url: `${environment.dubaiApiUrl}/organizations/{organization_id}`, method: 'get' },
  { name: 'getMarketplaces', url: `${environment.dubaiApiUrl}/organizations`, method: 'get' },
  { name: 'getShipper', url: `${environment.dubaiApiUrl}/organizations/{organization_id}/shippers`, method: 'get' },
  { name: 'getTicketSubjects', url: `${environment.torontoApiUrl}/ticket-types`, method: 'get'},
  { name: 'getSubStatuses', url: `${environment.torontoApiUrl}/ticket-sub-statuses`, method: 'get' },
  { name: 'getSubState', url: `${environment.torontoApiUrl}/ticket-statuses`, method: 'get' },
  { name: 'getTicketsDetail', url: `${environment.torontoApiUrl}/tickets/{ticket_id}/view-data`, method: 'get'},
  { name: 'getTicketsMasterData', url: `${environment.torontoApiUrl}/tickets/{ticket_id}/view-master-data`, method: 'get'},
  { name: 'getTicketsKanban', url: `${environment.torontoApiUrl}/tickets-kanban`, method: 'get'},
  { name: 'postResponseTicket',  url: `${environment.torontoApiUrl}/tickets/{ticket_id}/messages`, method: 'post'},
  { name: 'updateFiles', url: `${environment.torontoApiUrl}/tickets/{ticket_id}/attachments`, method: 'post', headers: {
    'Content-Type': 'multipart/form-data'
  }},
  { name: 'deleteFilesTicket', url: `${environment.torontoApiUrl}/view/tickets/{ticket_id}/attachments`, method: 'delete'},
  {name: 'getTicketExist', url: `${environment.torontoApiUrl}/tickets/exist`, method: 'get'},
  { name: 'createTickets', url: `${environment.torontoApiUrl}/tickets`, method: 'post' },
  { name: 'deleteRemoveMessage', url: `${environment.torontoApiUrl}/tickets/{ticket_id}/messages/view-data`, method: 'delete'},
  { name: 'getTrackingStatusesToronto', url: `${environment.torontoApiUrl}/delivery-statuses`, method: 'get' },
  { name: 'upgradeTickets', url: `${environment.torontoApiUrl}/tickets`, method: 'put' },
  {name: 'getColorsTags', url: `${environment.torontoApiUrl}/color-tags`, method: 'get'},
  {name: 'getColorsTagsByUser', url: `${environment.torontoApiUrl}/color-tags/user`, method: 'get'},
  {name: 'postColorsTagsByUser', url: `${environment.torontoApiUrl}/color-tags/user`, method: 'post'},
  {name: 'postColorsTicket', url: `${environment.torontoApiUrl}/tickets/color-tags/user`, method: 'post'}
]