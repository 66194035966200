export default {
  state: {
    carrierNY: [],
    carriersNY: [],
    carrierServicesNY: [],
    carrierServiceRatesNY: [],
    carrierServiceRateCoveragesNY: [],
    postFutureCoveragesNY:[],
    putUpdateCoveragesNY: [],
    postFreightChargesNY: [],
    putUpdateRateCoverageNY: [],
    historyFileRates: [],
    getHistoryFileInternationalRates: []
  },
  getters: {
    getCarrierNY: state => state.carrierNY,
    getCarriersNY: state => state.carriersNY,
    getCarrierServicesNY: state => state.carrierServicesNY,
    getCarrierServiceRatesNY: state => state.carrierServiceRatesNY,
    getCarrierServiceRateCoveragesNY: state => state.carrierServiceRateCoveragesNY,
    postFutureCoveragesNY: state => state.postFutureCoveragesNY,
    putUpdateCoveragesNY: state => state.putUpdateCoveragesNY,
    postFreightChargesNY: state => state.postFreightChargesNY,
    putUpdateRateCoverageNY: state => state.putUpdateRateCoverageNY,
    getHistoryFileRates: state => state.historyFileRates,
    getHistoryFileInternationalRates: state => state.getHistoryFileInternationalRates
  },
  mutations: {
    setCarrierNY(state, val) {
      state.carrierNY = {
        ...val
      }
    },
    setCarriersNY(state, val) {
      state.carriersNY = {
        rows: val.rows.map(el => ({...el, text: el.name}))
      }
    },
    setCarrierServicesNY(state, val) {
      state.carrierServicesNY = {
        rows: val.rows
      }
    },
    setCarrierServiceRatesNY(state, val) {
      state.carrierServiceRatesNY = {
        rows: val.rows
      }
    },
    setCarrierServiceRateCoveragesNY(state, val) {
      state.carrierServiceRateCoveragesNY = {
        rows: val.rows
      }
    },
    postFutureCoveragesNY(state, val) {
      state.postFutureCoveragesNY = {
        rows: val.rows
      }
    },
    putUpdateCoveragesNY(state, val) {
      state.putUpdateCoveragesNY = {
        rows: val.rows
      }
    },
    postFreightChargesNY(state, val) {
      state.postFreightChargesNY = {
        rows: val.rows
      }
    },
    putUpdateRateCoverageNY(state, val) {
      state.putUpdateRateCoverageNY = {
        rows: val.rows
      }
    },
    setHistoryFileRates(state, val) {
      state.historyFileRates = {
        rows: val.rows
      }
    },
    setHistoryFileInternationalRates(state, val) {
      state.getHistoryFileInternationalRates = {
        rows: val.rows
      }
    }
  }
}
