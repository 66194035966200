// directives/tooltipTarget.js
export default {
  inserted(el, binding, vnode) {
    const tooltipComponent = vnode.context.$refs[binding.arg]
    //if (tooltipComponent) {
    //  const rect = el.getBoundingClientRect()
    //  tooltipComponent.setPosition({
    //    top: rect.top + window.scrollY,
    //    left: rect.left + window.scrollX + rect.width + 10
    //  })
    //  tooltipComponent.checkVisibility()
    //}
  }
}
