import BaseServices from '@/store/services/index'
import * as fb from '@/firebase'
const baseService = new BaseServices()

export default {
  state: {
    usersTrackingPermission: []
  },
  getters: {
    getUsersTrackingPermission: state => state.usersTrackingPermission
  },
  mutations: {
    setUsersTrackingPermission(state, val) {
      state.usersTrackingPermission = val

    }
  },
  actions: {
    // fbUsersTrackingPermission ({commit}) {
    //   fb.db.collection('trackingPermission')
    //     .onSnapshot(resp => {
    //       commit('setUsersTrackingPermission', resp.data)
    //     })
    // }
  }
}
