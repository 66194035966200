 <template>
  <div/>
</template>

<script>
export default {
  data() {
    return {
      mostrarToast: false,
      mensajeToast: '',
      titleToast: '',
      tipoToast: 'danger'
    }
  },
  methods: {
    showError(title, message, type, showToast) {
      const fixedTitle = 'Ha ocurrido un error al generar el archivo:' // Mas general :)
      if (!showToast) {
        this.$alert(message, null, !!title ? `${fixedTitle}<br><span>${title}</span>` : fixedTitle)
      } else {
        this.generateToast({title, message}, type, fixedTitle)
      }
    },
    generateToast({title, message}, type = 'danger', fixedTitle) {
      const h = this.$createElement
      this.$bvToast.toast(message, {
        'title': [
          h('div', {class: ['row px-1']}, [
            h('div', {}, fixedTitle),
            h('div', {class: ['font-weight-bold']}, title)
          ])
        ],
        'variant': type,
        'no-hover-pause': true,
        'solid': true
      })
    }
  }
}
</script>

<style lang="scss">
#swal2-title{
  font-size: 1.4rem;
}
#swal2-title > span{
  font-size: 1.2rem;
}
</style>
