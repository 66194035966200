import { v4 as uuidv4 } from 'uuid'
export default {
  state: {
    advancedRules: [],
    advancedRule: {},
    advancedRulesByOrganization: [],
    advancedRuleByOrganization: {},
    codeLocation: {}
  },
  getters: {
    getAdvancedRules: state => state.advancedRules,
    getAdvancedRule: state => state.advancedRule,
    getAdvancedRulesByOrganization: state => state.advancedRulesByOrganization,
    getAdvancedRuleByOrganization: state => state.advancedRuleByOrganization,
    getLocationCode: state => state.codeLocation,
    getLocationCodeByOrganization: state => state.codeLocation
  },

  mutations: {
    setAdvancedRule (state, val) {
      state.advancedRule = val
    },
    setAdvancedRules (state, val) {
      state.advancedRules = {
        rows: val.rows.map(rule => {
          return {
            _id: rule.id,
            ...rule,
            id: uuidv4()
          }
        }),
        total: val.total || 0
      }
    },
    setAdvancedRuleByOrganization (state, val) {
      state.advancedRuleByOrganization = val
    },
    setAdvancedRulesByOrganization (state, val) {
      state.advancedRulesByOrganization = {
        rows: val.rows.map(rule => {
          return {
            _id: rule.id,
            ...rule,
            id: uuidv4()
          }
        }),
        total: val.total || 0
      }
    },
    setLocationCodeByOrganization (state, val) {
      state.codeLocation = val
    },
    setLocationCode (state, val) {
      state.codeLocation = val
    }
  },
  actions: {}
}