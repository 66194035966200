<template>
  <b-popover 
    ref="popover"
    :target="target"
    :placement="placement || 'auto'"
    :show.sync="visible"
    :key="keyTooltipRender"
    :custom-class="`final-tooltip ${classTooltip}`"
    :disabled="true"
  >
    <template #title>
        <div class="flex justify-content-between">
          <article class="flex gap-2 items-center justify-content-center">
            <img src="/icon/sparkle.svg" class="tooltip-icon" />
            <span>
              Nuevo
            </span>
          </article>
          <feather-icon icon="XIcon" size="18" class="cursor-pointer" @click="closeTooltip"/>
        </div>
    </template>
    <p class="BrrrrAnuel">{{text}}</p>
  </b-popover>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
export default {
  components: { FeatherIcon },
  props: {
    tooltipId: {
      type: String,
      required: true
    },
    expirationDate: {
      type: String,
      required: false
    },
    target: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      required: false
    },
    classTooltip: {
      type: [Array, String],
      required: false
    }
  },
  data() {
    return {
      visible: true,
      keyTooltipRender: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.waitForElements(() => this.checkVisibility())
    })
  },
  methods: {
    checkVisibility() {
      const storedData = localStorage.getItem(this.tooltipId)
      if (storedData) {
        const { expirationDate } = JSON.parse(storedData)
        if (expirationDate && new Date() <= new Date(expirationDate)) {
          localStorage.removeItem(this.tooltipId)
          this.visible = true
          this.keyTooltipRender++
        } else {
          this.visible = false
          this.keyTooltipRender++
        }
      } else {
        this.visible = true
        this.keyTooltipRender++
      }
    },
    waitForElements(callback) {
      const elementsToCheck = [
        document.querySelector(`#${this.target}`)
      ]
      const allElementsPresent = elementsToCheck.every(el => el !== null)

      if (allElementsPresent) {
        this.elementsReady = true
        callback()
      } else {
        setTimeout(() => {
          this.waitForElements(callback)
        }, 50)
      }
    },
    closeTooltip() {
      this.visible = false
      this.keyTooltipRender++
      localStorage.setItem(this.tooltipId, JSON.stringify({ expirationDate: this.expirationDate, visible: false }))
    }
  }
}
</script>

<style>
.tooltip-icon {
  background-color: white;
  border-radius: 50%;
  padding: 8px;
  width: 28px;
  height: 28px;
  margin-right: 10px;
}

.final-tooltip {
  background: #1967D2 !important;
  max-width: 356px !important;
  max-height: 98px !important;
  border-radius: 1rem !important;
}

.final-tooltip .popover-header {
  background: #1967D2 !important;
  font-size: 16px !important;
  border: none !important;
  border-radius: 1rem !important;
  padding-top: 1rem !important;
  padding-bottom: 0rem !important;  
}

.final-tooltip .popover-body {
  background: #1967D2 !important;
  border-radius: 1rem !important;
  padding-top: 0rem !important;
}

.final-tooltip .popover-body {
  color: #F5F5F5 !important;
  font-size: 12px !important;
  border: none !important;
}

.BrrrrAnuel {
  margin-left: 2.8rem;
  margin-bottom: 0 !important;
  list-style: none !important;
}
</style>
