<template>
  <div class="show-html-lists">
    <summernote :content.sync="myValue"/>
  </div>
</template>
<script>
import Summernote from '@/views/components/custom/summernote/Summernote.vue'

export default {
  props: ['maxLength', 'value', 'placeholder', 'msg', 'rows', 'disabled'],
  name: 'field-rte',
  components: { Summernote },
  data () {
    return {
      editor: null,
      counterDanger: true,
      myValue: undefined
    }
  },
  watch: {
    value () { 
      this.myValue = this.value
    },
    myValue () {
      this.$emit('update:value', this.myValue)
    }
  },
  computed: {
    inputVal: {
      get () {
        return this.myValue
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    document.querySelectorAll('.data-autoresize').forEach(function (element) {
      const textarea = element.querySelector('.vs-textarea')
      textarea.style.boxSizing = 'border-box'
      const offset = textarea.offsetHeight - textarea.clientHeight
      textarea.addEventListener('input', function (event) {
        event.target.style.height = 'auto'
        event.target.style.height = `${event.target.scrollHeight + offset}px`
        event.target.style.minHeight = `${event.target.scrollHeight + offset}px`
      })
      element.classList.remove('data-autoresize')
    })
    this.myValue = this.value
  }
}
</script>
<style lang="scss">

$colors: ('primary','dark','danger','warning','success','special','theme','info','light-blue');;

@each $background, $text in $colors {
  .vs-component.vs-con-textarea.#{$background}{
    border: 1px solid rgba(var(--vs-#{$background}),1) !important;
  }
}
</style>
