
export default {
  state: {
    countriesCollection: [],
    carriersCollection: [],
    collectionOnDelivery: []
  },
  getters: {
    getCountriesCollection: (state) => state.countriesCollection,
    getCarriersCollection: state => state.carriersCollection,
    getCollectionOnDelivery: state => state.collectionOnDelivery
  },
  mutations: {
    setCountriesCollection(state, val) {
      state.countriesCollection = {
        rows: val.rows.map(el => ({...el, text: el.name, _id: el.id}))
      }
    },
    setCarriersCollection(state, val) {
      state.carriersCollection = {
        rows: val.rows.map(el => ({...el, text: el.name, _id: el.id}))
      }
    },
    setCollectionOnDelivery(state, val) {
      state.collectionOnDelivery = {
        rows: val.rows
      }
    }
  }
}
