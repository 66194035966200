import { environment } from '@/environments/environment'

export default [
  { name: 'getManageBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/business-data`, method: 'get' },
  { name: 'getBillingDataCompanyById', url: `${environment.miamiApiUrl}/v1/billing/companies/{id}`, method: 'get' },
  { name: 'getBusinessDataNotCreated', url: `${environment.miamiApiUrl}/v1/billing/companies?filter[businessDataCreated]=0&filter[businessDataEnabled]=1`, method: 'get' },
  { name: 'getSearchOrganizationData', url: `${environment.miamiApiUrl}/v1/billing/companies`, method: 'get' },
  { name: 'getManageDataCompanyHistory', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/business-data/history`, method: 'get' },
  { name: 'getActivationStatusDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/activation/statuses`, method: 'get' },
  { name: 'downloadDataExcel', url: `${environment.miamiApiUrl}/v1/billing/companies/business-data/generate-file`, method: 'get', notCancel: true, responseType: 'blob'},
  { name: 'getCompanyInfoBilling', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/has-business-data`, method: 'get'},
  { name: 'saveManageBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/business-data`, method: 'post' },
  { name: 'sendNotificationBulk', url: `${environment.miamiApiUrl}/v1/billing/invoices/documents/email-notification-bulk`, method: 'post'},
  { name: 'uploadInvoicesGroupBulk', url: `${environment.miamiApiUrl}/v1/billing/invoices/documents/invoice-files-upload-bulk`, method: 'post', headers: {'Content-Type': 'multipart/form-data'}},
  { name: 'uploadIndemnification', url: `${environment.miamiApiUrl}/v1/billing/indemnifications/excel-file`, method: 'post'},
  { name: 'updateManageBillingDataCompanyStatus', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/{status_type}`, method: 'post' },
  { name: 'disableMasiveManageBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/deactivate-bulk`, method: 'post' },
  { name: 'uploadRecaudosManageBilling', url: `${environment.miamiApiUrl}/v1/billing/delivery-collections/excel-file?{queryParams}`, method: 'post' },
  { name: 'uploadCollectionPaymentsBillingData', url: `${environment.miamiApiUrl}/v1/billing/delivery-collection-payments/excel-file?{queryParams}`, method: 'post' },
  { name: 'updateMasiveManageBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/business-data/massive-updater`, method: 'put' },
  { name: 'updateManageBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/business-data`, method: 'put' },
  { name: 'deleteManageBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/business-data`, method: 'delete', notCancel: true },
  // Costos
  // { name: 'uploadCostFileBillingDataCompanyOld', url: `${environment.miamiApiUrl}/v1/billing/delivery-importer/file-upload?allowDuplicateDeliveries={avoid_duplicated}`, method: 'post' },
  { name: 'uploadCostFileBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/delivery-costs/excel-file{params_values}`, method: 'post' },
  // Facturas
  { name: 'getInvoiceBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/invoices/documents`, method: 'get' },
  { name: 'downloadInvoiceBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/invoices/documents/{invoice_number}/invoice-files/{invoice_file_id}`, method: 'get'},
  { name: 'uploadBillFileBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/invoices/documents/{invoice_number}/invoice-files`, method: 'put' },
  { name: 'sendEmailBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/invoices/documents/{invoice_number}/email-notification`, method: 'post'},
  { name: 'deleteInvoiceBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/invoices/documents/{invoice_number}/invoice-files/{invoice_file_id}`, method: 'delete', notCancel: true },
  { name: 'getInvoiceBillingDataCompanyById', url: `${environment.miamiApiUrl}/v1/billing/invoices/documents/files/{file_id}`, method: 'get' },
  // Notas
  { name: 'getNotesBillingDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/invoices/notes`, method: 'get' },
  // Indemnizaciones
  { name: 'getIndemnificationsDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/indemnifications`, method: 'get' },
  { name: 'sendEmailIndemnificationsDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/indemnifications/{indemnification_id}/email-notification`, method: 'post' },
  { name: 'getHistoryAjust', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/indemnification-adjustments`, method: 'get' },
  { name: 'getHistoryAjustReport', url : `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/indemnification-adjustments/excel-file`, method: 'get', notCancel: true, responseType: 'blob'},
  //Recaudo contra entregas
  { name: 'getColletionsDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/deliveries/collection-data`, method: 'get' },
  { name: 'downloadColletionsDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/deliveries/collection-data/generate-file`, method: 'get', notCancel: true, responseType: 'blob' },
  //Traducción de places
  { name: 'getCarriersBilling', url: `${environment.miamiApiUrl}/v1/billing/carriers`, method: 'get' },
  //DEscargar excel
  { name: 'downloadTranslate', url: `${environment.miamiApiUrl}/v1/billing/carriers/{id}/place-aliases`, method: 'get', notCancel: true, responseType: 'blob' },
  //Upload de archivos
  { name: 'uploadTranslate', url: `${environment.miamiApiUrl}/v1/billing/carriers/{id}/place-aliases`, method: 'post', headers: {'Content-Type': 'multipart/form-data'}},
  { name: 'downloadColletionsDataCompany', url: `${environment.miamiApiUrl}/v1/billing/companies/{company_id}/deliveries/collection-data/generate-file`, method: 'get', notCancel: true, responseType: 'blob' },

  //Export other services
  { name: 'exportOtherServices', url: `${environment.miamiApiUrl}/v1/billing/delivery-costs/other-services`, method: 'post' },

  //Control billing
  { name: 'getControlBilling', url: `${environment.miamiApiUrl}/v1/billing/countries/{country_id}/deliveries-costs`, method: 'get', notCancel: true },
  { name: 'getBillableCompanies2', url: `${environment.miamiApiUrl}/v1/billing/countries/{country_id}/companies-billable`, method: 'get', notCancel: true },
  { name: 'generateBillingControlReport', url: `${environment.miamiApiUrl}/v1/billing/countries/{country_id}/deliveries-costs/generate-file`, method: 'get', notCancel: true, responseType: 'blob' },
  { name: 'getCouriersControl', url: `${environment.miamiApiUrl}/v1/billing/countries/{country_id}/carriers`, method: 'get', notCancel: true }
]
