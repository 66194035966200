<template>
  <div>
    <b-form-tags
      v-if="!newTagsDesign"
      :class="['tags--height', showAdd? 'show-add' : '']"
      v-model="myValue"
      input-id="tags-remove-on-delete"
      :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
      :tagPills="type === 'pills'"
      :placeholder="placeholder"
      remove-on-delete
      no-add-on-enter
      add-on-change
      @input="onInput"
      v-bind="tagsOption"
      :disabled="disabled"
      :separator="[' ', ',', ';'].concat(tagsOption.separator || [])"
      :tagVariant="color || 'primary'"
    />
    <b-form-tags
      v-if="newTagsDesign"
      v-model="myValue"
      @input="resetInputValue"
      class="mb-2 custom-tags"
      no-outer-focus
      :placeholder="placeholder"
      :tag-validator="tagsOption && tagsOption.validateEmail ? validatorEmail : null"
      @tag-state="onTagState"
      :disabled="disabled"
      :limit="tagsOption && tagsOption.limit || null">
      <template v-slot="{tags, inputId, placeholder, inputHandlers, addTag, removeTag }">
        <b-input-group>
          <div v-if="icon" class="custom-icon" :class="disabled ? 'custom-icon-disabled' : ''">
            <feather-icon
            :size="icon.size || '20'"
            :icon="icon.type || 'TagIcon'"
            :stroke-width="icon.stroke || '1'" 
            :color="icon.color || '#6e6b7b'"
            />
          </div>
          <b-form-input
            v-model="newTag"
            :id="inputId"
            :placeholder="placeholder"
            v-on="inputHandlers"
            :state="(typeof msg === 'undefined') ? undefined : false"
            class="form-control col-md-10 custom-input"
            :disabled="disabled"
          />
          <b-input-group-append>
            <b-button
              v-if="!disabled"
              variant="outline-light"
              v-b-tooltip.hover
              class="custom-button"
              :disabled="disabled"
              @click="addTag(newTag)"
              ><feather-icon icon="PlusIcon"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback :state="state" class="margin-text-invalid">
          {{ tagsOption && tagsOption.duplicateTagText || 'Tag duplicado no se puede volver a agregar' }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="limitTags" class="margin-text-invalid">
          {{ tagsOption && tagsOption.limitTagText  || 'Limite de tags alcanzados' }}
        </b-form-invalid-feedback>
        <span v-if="invalidEmail" class="margin-text-invalid d-block invalid-feedback is-invalid">
            Correo inválido
        </span>
        <b-form-invalid-feedback 
          class="margin-text-invalid" :state="(typeof msg === 'undefined') ? undefined : false"
          v-if="msg && msg.text && !invalidEmail">
          {{msg.text}}
        </b-form-invalid-feedback>
        <ul v-if="tags.length > 0" class="list-unstyled d-inline-flex flex-wrap mb-1 ml-0">
          <li v-for="tag in tags" :key="tag" :title="`Tag: ${tag}`" class="margin-li-tags">
            <b-badge :style="styleTag" class="d-flex align-items-center">
                <strong>{{ tag }}</strong>
                <b-button
                size="sm"
                variant="link"
                @click="removeTag(tag)"
                class="custom-remove-tag"
                :style="styleCloseBtnTag"
                >
                X
                </b-button>
            </b-badge>
          </li>
        </ul>
          </template>
    </b-form-tags>
  </div>
</template>
<script>
export default {
  name: 'field-tags',
  props: ['value', 'disabled', 'change', 'name', 'tagsOption', 'color', 'type', 'placeholder', 'showAdd', 'onEnter', 'newTagsDesign', 'icon', 'msg'],
  data () {
    return {
      myValue: [],
      lastValid: [],
      lastInvalid: [],
      lastDuplicated: [],
      newTag: '',
      invalidEmail: false
    }
  },
  mounted () {
    this.myValue = this.value
  },
  watch: {
    value: {
      handler() {
        this.myValue = this.value
      }, deep: true
    }
  },
  computed: {
    state() {
      const state = !!this.myValue ? this.myValue.indexOf(this.newTag.trim()) > -1 ? false : null : null
      return state
    },
    limitTags() {
      const limit = !!this.myValue ? this.myValue.length === this.tagsOption?.limit ? false : null : null
      return limit
    },
    styleTag () {
      return {
        backgroundColor: `${this.tagsOption?.customTag?.background || '#044389'} !important`, 
        color:  `${this.tagsOption?.customTag?.color || '#fff'} !important` 
      }
    },
    styleCloseBtnTag () {
      return {
        color:  `${this.tagsOption?.customTag?.colorBtnClose || '#fff'} !important` 
      }
    }
  },
  methods: {
    // onTagState (valid, invalid, duplicate) {
    //   this.lastValid = valid
    //   this.lastInvalid = invalid
    //   this.lastDuplicated = duplicate
    // },
    // onChange (as, ds) {
    // },
    // onKeydown (as, ds) {
    // },
    onInput (as, ds) {
      this.$emit('update:value', this.myValue)
      if (this.change) this.change(this.name, this.myValue)
    },
    resetInputValue() {
      this.newTag = ''
      this.$emit('update:value', this.myValue)
    },
    validatorEmail(email) {
      // const filter = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
      //eslint-disable-next-line
      const filter = /^[A-Za-z0-9_\-!#$%&ñÑ'*/=?^`{|}~+]([\.]?[A-Za-z0-9_\-!#$%&ñÑ'*/=?^`{|}~+])+@([A-Za-z0-9_\-.!#$%&ñÑ'*/=?^`{|}~+])+\.([A-Za-z]{2,4})$/
      this.invalidEmail = !filter.test(email)
      return filter.test(email)
    },
    onTagState (valid, invalid, duplicate) {
      this.invalidEmail = !invalid.length === 0
    }
  }
}
</script>
<style lang="scss">
.tags--height {
  min-height: 38px;
}

.b-form-tags:not(.show-add) .b-form-tags-list .b-from-tags-field button {
  display: none;
}
.custom-button {
    padding: 0.8rem !important;
    margin-left: 0.6rem;
    border-radius: 5px !important;
}
.custom-input {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
    height: 2.7rem;
}
.custom-remove-tag{
    padding: 0 0.4rem !important;
    font-weight: bold !important;
}
.custom-icon {
    background: #FAFAFA;
    display: flex;
    align-items: center;
    border: 1px solid #d8d6de;
    padding: 0rem 0.9rem;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}
.custom-icon-disabled {
  background: none;
  border: none;
}
.custom-tags {
    border: none !important;
    padding: 0 !important;
}
.b-form-tags:focus, .b-form-tags.focus{
    background-color: none !important;
    box-shadow: none !important;
}
.margin-li-tags{
    margin: 1rem 0.5rem 0rem 0rem;
}
.margin-text-invalid {
    margin-top: 1rem;
}
</style>
