import { environment } from '@/environments/environment'
export default [

  { name: 'getDeliveryStatus', url: `${environment.shanghaiApiUrl}/delivery-status`, method: 'get'},
  { name: 'getDeliveryStatuses', url: `${environment.shanghaiApiUrl}/delivery-statuses`, method: 'get'},
  { name: 'getTicketTypesByDelivery', url: `${environment.shanghaiApiUrl}/delivery-statuses/{code}/ticket-types`, method: 'get'},
  { name: 'getTicketTypesByDeliveryToronto', url: `${environment.torontoApiUrl}/delivery-statuses/{code}/ticket-types`, method: 'get'},


  { name: 'getDeliveries', url: `${environment.beijingApiUrl}/deliveries`, method: 'get'},
  { name: 'getFilterServices', url: `${environment.beijingApiUrl}/filters`, method: 'get'},
  // { name: 'getDeliveries', url: 'http://localhost:8000/deliveries/v1/deliveries', method: 'get'},
  // { name: 'getFilterServices', url: 'http://localhost:8000/deliveryFilters/v1/deliveryFilters', method: 'get'},
  { name: 'getDeliveryWebTracking', url: `${environment.santiagoApiUrl}/companies/{shipper_id}/deliveries/{id}/web-tracking`, method: 'get'},
  { name: 'getReturnWebTracking', url: `${environment.santiagoApiUrl}/companies/{shipper_id}/returns/{id}/web-tracking`, method: 'get'},

  //MIGRACION
  //DOC: https://enviame-latam.atlassian.net/l/cp/wKyBhfhD
  { name: 'getDeliveriesPlat', url: `${environment.apiPlatform}/v1/deliveries`, method: 'get', notCancel: true},
  { name: 'deleteDeliveries', url: `${environment.apiPlatform}/v1/deliveries`, method: 'delete'},
  { name: 'deleteDeliveriesByID', url: `${environment.apiPlatform}/v1/deliveries/{delivery_id}`, method: 'delete' },
  { name: 'getSellersPlat', url: `${environment.apiPlatform}/v1/marketplaces/{company_id}/sellers`, method: 'get'},
  { name: 'getPudosPlat', url: `${environment.apiPlatform}/v1/pudos`, method: 'get'},
  { name: 'getMacroStatesPlat', url: `${environment.apiPlatform}/v1/meta-statuses`, method: 'get'},
  { name: 'getDeliveriesReportPlat', url: `${environment.apiPlatform}/v1/deliveries-report`, method: 'get' },
  { name: 'getDeliveriesReportURLPlat', url: `${environment.apiPlatform}/v1/deliveries-export`, method: 'get', notCancel: true, responseType: 'blob' },
  { name: 'getDeliveriesReportPlatOld', url: `${environment.apiPlatform}/v1/deliveries-export-old`, method: 'get' },
  { name: 'findStimatedPrice', url: `${environment.apiPlatform}/v1/deliveries/estimated-price-delivery`, method: 'post'},
  { name: 'getPlaceByPlatId', url: `${environment.apiPlatform}/v1/places/{platform_id}/details`, method: 'get'},
  { name: 'uploadDeliveriesMasive', url: `${environment.apiPlatform}/v1/deliveries/upload`, method: 'post' },
  { name: 'postNewDelivery', url: `${environment.apiPlatform}/v1/deliveries`, method: 'post' },
  { name: 'getPlacesPlat', url: `${environment.apiPlatform}/v1/places/{country_id}`, method: 'get' },

  { name: 'getDeliveryDetails', url: `${environment.apiPlatform}/v1/deliveries/{delivery_id}/details`, method: 'get'},
  { name: 'getForceTracking', url: `${environment.apiPlatform}/v1/tracking/{delivery_id}`, method: 'get'},
  { name: 'deleteTracking', url: `${environment.apiPlatform}/v1/tracking/{delivery_id}`, method: 'delete'},
  { name: 'deleteDeliveryPlat', url: `${environment.apiPlatform}/v1/deliveries/{delivery_id}/cancel`, method: 'delete'},
  { name: 'postDispatchDelivery', url: `${environment.apiPlatform}/v1/dispatcher`, method: 'post'},
  { name: 'putChangeCarrier', url: `${environment.apiPlatform}/v1/carrier-reinject`, method: 'put'},
  { name: 'postChangeStatusDelivery', url: `${environment.apiPlatform}/v1/deliveries/{delivery_id}/status`, method: 'post'},
  { name: 'getPudosCompany', url: `${environment.apiPlatform}/v1/pudos?company_id={company_id}`, method: 'get'},
  { name: 'putUpdateDelivery', url: `${environment.apiPlatform}/v1/deliveries/{delivery_id}`, method: 'put'},
  { name: 'postDuplicateDelivery', url: `${environment.apiPlatform}/v1/deliveries/{delivery_id}/duplicate`, method: 'post'},
  { name: 'putChangeMultipackage', url: `${environment.apiPlatform}/v1/deliveries/{delivery_id}/npackages`, method: 'put'},
  { name: 'getResendEmail', url: `${environment.apiPlatform}/v1/deliveries/{delivery_id}/resend`, method: 'get', responseType: 'text'},
  { name: 'postCreateReturn', url: `${environment.apiPlatform}/v1/return-deliveries/{delivery_id}`, method: 'post' },
  { name: 'getReinjectionCarriersById', url: `${environment.apiPlatform}/v1/reinjection-company-carriers-by-id`, method: 'get'},
  { name: 'getIsCancelable', url: `${environment.apiPlatform}/v1/delivery/carrier/{carrier_code}/iscancelable`, method: 'get'}
  
]