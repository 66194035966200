<template>
  <div class="range">
    <input type="range" v-model="myValue" name="range" list="tickmarks" :min="specsRange.min" :max="specsRange.max" step="1" @change="onChange">
    <datalist id="tickmarks">
      <option v-for="n in specsRange.max" :key="n" :value="n" :label="`${n} ${specsRange.label}`"><div class="text-center">|</div></option>
    </datalist>
  </div>
</template>

<script>
export default {
  name: 'field-range',
  props: { 
    value: {
      type: Number
    },
    specsRange: {
      type: Object
    }
  },
  data() {
    return {
      myValue: this.value || this.specsRange.min
    }
  },
  computed: {},
  mounted() {
    this.myValue = this.value || this.specsRange.min
  },
  watch: {
    // value() {
    // }
  },
  methods: {
    onChange() {
      this.$emit('update:value', Number(this.myValue))
    }
  }
}
</script>


<style lang="scss" scope>

datalist {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 1%;
  > option {
    text-align: center;
    font-size: 11px;
    color: #bbbbbb;
  }

}

@mixin rangeThumb {
  width: 1.285rem;
  height: 1.285rem;
  margin: -8px 0  0;
  border-radius: 50%;
  background: #044389;
  cursor: pointer;
  border: 0 !important;
}

@mixin rangeTrack {
  border-radius: 5px;
  width: 100%;
  height: 7px;
  cursor: pointer;
  background: #D9D9D9;
}

input {
  width: 100%;
  padding-right: 1%;
  padding-left: 1%;
  -webkit-appearance: none;
  // Thumb
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include rangeThumb;
  }

  &::-moz-range-thumb {
    @include rangeThumb;
  }

  &::-ms-thumb {
    @include rangeThumb;
  }

  // Track
  &::-webkit-slider-runnable-track {
    @include rangeTrack;
  }

  &::-moz-range-track {
    @include rangeTrack;
  }

  &::-ms-track {
    @include rangeTrack;
  }

  &:focus {
    background: none;
    outline: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
}

// // Labels below slider
// .range-labels {
//   margin: 18px -41px 0;
//   padding: 0;
//   list-style: none;
  
//   li {
//     position: relative;
//     float: left;
//     width: 90.25px;
//     text-align: center;
//     color: #b2b2b2;
//     font-size: 14px;
//     cursor: pointer;
    
//     &::before {
//       position: absolute;
//       top: -25px;
//       right: 0;
//       left: 0;
//       content: "";
//       margin: 0 auto;
//       width: 9px;
//       height: 9px;
//       background: #b2b2b2;
//       border-radius: 50%;
//     }
//   }
  
//   .active {
//     color: #37adbf;
//   }
  
//   .selected::before {
//     background: #37adbf;
//   }
  
//   .active.selected::before {
//     display: none;
//   }
// }

</style>
