import BaseServices from '@/store/services/index'
const baseService = new BaseServices()
  
export default {
  state: {
    putStore: [],
    getStore: []
  },

  getters: {
    putStore: state => state.putStore,
    getStore: state => state.getStore
  },

  mutations: {
    setPutStore(state, val) {
      state.putStore = {
        rows: val.rows.map(pudos => {
          return {
            ...pudos
          }
        })
      }
    },
    setStore(state, val) {
      state.getStore = val.rows.map(store => {
        return {
          ...store
        }
      })
    }
  }
}