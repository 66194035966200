<template>
  <b-media no-body>
    <b-media-aside class="mr-1">
      <b-avatar size="40" :variant="variant">
        <feather-icon size="18" :icon="icon" />
      </b-avatar>
    </b-media-aside>
    <b-media-body class="my-auto">
      <b-card-text class="font-small-2 mb-0" v-html="title"></b-card-text>
      <h6 v-if="!useSlot" class="font-weight-bold mb-0 font-small-2" :class="capitalizeContent && 'text-capitalize'" v-html="content"></h6>
      <!-- <template #:[key]>
        <h6 class="font-weight-bold mb-0 font-small-2" :class="capitalizeContent && 'text-capitalize'">
          <span v-if="useSlot"><slot></slot></span>
        </h6>
      </template> -->
      <slot v-if="useSlot" :name="keySlot" class="font-weight-bold mb-0 font-small-2"></slot>
    </b-media-body>
  </b-media>
</template>
<script>
export default {
  name: 'media-info',
  props: {
    title: { type: String, default: 'Sin Información' },
    content: { type: String, default: 'Sin información' },
    variant: { type: String, default: 'primary' },
    icon: { type: String, default: 'CreditCardIcon' },
    useSlot: { type: Boolean, default: false },
    keySlot: { type: String, default: '' },
    capitalizeContent: null
  }
}
</script>
<style lang="scss">
  
</style>