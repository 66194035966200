<template>
  <div>
    <b-input-group
      v-bind="optionGroup"
    >
      <div v-if="indicator" class="indicator--container">
        <p class="indicator--class">{{indicator}}</p>
      </div>
      <b-form-file
        :placeholder="placeholder"
        :type="type || 'text'"
        :class="['input-icon-validate-'+(msg && msg.type? msg.type : ''), !!action? 'with-action' : '']"
        :name="name"
        :disabled="disabled"
        :no-drop="noDrop"
        v-model="myValue"
        @input="onChange"
        :options="options"
        v-mask="mask"
        :multiple="multiple"
        :min="min"
        :file-name-formatter="formatNames"
        drop-placeholder="Suelta los archivos aquí"
        :max="max"
        :state="(typeof msg === 'undefined') ? undefined : false"
        :accept="accept ? accept : 'image/*, .pdf'"
        :browse-text="browseText ? browseText : 'Buscar archivo'"
      />
    </b-input-group>
    <span v-if="clearable" :style="{opacity: myValue? 1 : 0}" class="clean-input mt-auto mb-auto" @click="myValue = '';">
    </span>
    <custom-alert-field v-if="msg && msg.text" :class="clearable? 'in-input' : ''"
      :text="msg && msg.text? msg.text : ''"
      :type="msg && msg.type? msg.type : ''"/>
  </div>
</template>
<script>
import CustomAlertField from '@/views/components/custom/form-render/fields/CustomAlertField.vue'
export default {
  props: ['value', 'placeholder', 'type', 'msg', 'mask', 'clearable', 'disabled', 'change', 'name', 'action', 'min', 'max', 'optionGroup', 'options', 'indicator', 'multiple', 'noDrop', 'accept', 'browseText', 'adminVersion'],
  name: 'field-file',
  data () {
    return {
      myValue: undefined,
      myType: undefined,
      myOptionGroup: undefined
    }
  },
  components: { CustomAlertField },
  watch: {
    value () {
      this.myValue = this.value
    }
  },
  methods: {
    onChange () {
      this.$emit('update:value', this.myValue)
      if (this.change) this.change(this.name, this.myValue)
    },
    removeField () {
      this.action.fx(this.name, this.myValue)
    },
    formatNames (files) {
      return files.length === 1 ? files[0].name : `${files.length} Archivos Seleccionados`
    }
  }
}
</script>
<style lang="scss">
.with-action > .vs-con-input{
  width: calc(100% - 40px);
}

span.clean-input {
    height: 0px;
    width: 100%;
    position: relative;
    display: block;
}

.clean-input::before{
    position: relative;
    z-index: 10;
    left: calc(100% - 25px);
    cursor: pointer;
    // padding: 5px;
    padding: 0px 7px;
    top: -27px;
    color: #6e6b7b;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 40 40' fill='none' stroke='gray' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x w-4 h-4 mt-1'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
}
.input-icon-validate-danger{
  border: 1px solid rgba(var(--vs-danger), 1);
}
.indicator--class {
  margin: 0;
}
.indicator--container {
  align-self: center;
  padding: 5px;
}
// .vs-inputx{
//   font-size: 1rem;
//   color: inherit;
//   font-family: "Montserrat", Helvetica, Arial, sans-serif;
// }
// .input-span-placeholder.vs-input--placeholder{
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
// .vs-con-input-label.vs-input.input-icon-validate-danger > .vs-con-input >
// .input-icon-validate{
//   display: none;
// }
</style>
