import { environment } from '@/environments/environment'

export default [
  { name: 'getAssignments', url: `${environment.torontoApiUrl}/tickets/assignments`, method: 'get', headers:{'x-compression': true, 'Accept-Encoding': 'gzip, deflate, br', 'Content-type': 'application/json'}  },
  { name: 'getPriorityLevels', url: `${environment.torontoApiUrl}/priority-levels`, method: 'get' },
  { name: 'getUsersBo', url: `${environment.torontoApiUrl}/bo-users`, method: 'get' },
  { name: 'getCategoriesBo', url: `${environment.torontoApiUrl}/task-bo-categories`, method: 'get' },
  { name: 'setUsersAssign', url: `${environment.torontoApiUrl}/tickets/assignments`, method: 'POST' },
  { name: 'deleteUsersAssign', url: `${environment.torontoApiUrl}/tickets/assignments`, method: 'DELETE' },
  { name: 'setCompleteAssign', url: `${environment.torontoApiUrl}/tickets/assignments/completed`, method: 'POST' },
  { name: 'getCarrierSearch', url: `${environment.torontoApiUrl}/carriers`, method: 'get' },
  { name: 'getShippersSearch', url: `${environment.torontoApiUrl}/shippers`, method: 'get' },
  { name: 'setStatusUser', url: `${environment.torontoApiUrl}/bo-users/{idUser}`, method: 'put' },
  { name: 'getUsersBoConfig', url: `${environment.torontoApiUrl}/bo-users-config`, method: 'get' },
  { name: 'postUsersBoConfig', url: `${environment.torontoApiUrl}/bo-users-config`, method: 'put' }
]