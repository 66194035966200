<template>
  <div class="mb-1">
    <label class="label-form" v-if="label">{{ label }}</label>
      <div class="d-flex">
        <b-dropdown id="dropdown-filter-input" :variant="!!valueTags.length ? 'primary' : iconLabel ? 'outline-primary' : 'outline-secondary'" ref="dropdown"  menu-class="w-100 filterInput-menu-class" :no-caret="iconLabel" :class="iconLabel && 'btn-cnt-filter--icon'">
          <template #button-content v-if="iconLabel">
            <b-spinner v-if="loading"/>
            <feather-icon v-else icon="FilterIcon" size="1.5x" :class="!!valueTags.length ? 'text-white' : 'text-primary'"/>
          </template>
          <template #button-content v-else>{{ dropdownText ? dropdownText: 'Seleccione un filtro' }}</template>
          <b-overlay
            v-if="showLoading"
            :show="loading"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
            class="filterInput-overlay">
            <div v-for="(option, index) in options" :key="`${index}_filterInput`">
              <b-form-group :label="option.title" v-slot="{ ariaDescribedby }" class="px-1">
                <b-form-radio-group
                  :aria-describedby="ariaDescribedby"
                  v-model="valuesFilterSelect[index]"
                  :options="option.values"
                  value-field="item"
                  text-field="item.name"
                  disabled-field="notEnabled"
                  :stacked="true"
                  @change="change"
                ></b-form-radio-group>
              </b-form-group>
              <b-dropdown-divider v-if="(index + 1) !== options.length" class="filterInput-dropdown-divider"></b-dropdown-divider>
            </div>
          </b-overlay>
        </b-dropdown>
      <div v-if="!!valueTags.length" class="d-flex flex-wrap align-content-end" :key="tagsRender">
        <b-badge  variant="outline-primary" class="badge-cnt-filter" v-for="(data, index) in valueTags" :key="`${index}_valueTags`">{{data.name}} <feather-icon @click="removeTagDestination(index)" icon="XIcon" size="20"  class="warning filterInput-XIcon"/>
        </b-badge>
        <div v-if="valueTags.length >= 2" class="d-flex align-items-center pl-1">
          <feather-icon v-b-tooltip.hover.top="$t('Limpiar filtros')" @click="clearAll" icon="XIcon" size="15" class="warning filterInput-XIcon"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'filter-select-group',
  props: ['options', 'onChange', 'loadingStatus', 'dontShowLoading', 'label', 'dropdownText', 'selectDefault', 'iconLabel'],
  data () {
    return {
      tagsRender: 0,
      valueTags:[],
      valuesFilterSelect: [],
      loading: false,
      showLoading: true,
      lastValueIndexSearch: null
    }
  },
  mounted() {
    this.generateSelectArr()
    if (this.dontShowLoading) this.showLoading = false
  },
  watch: {
    loadingStatus() {
      if (this.loadingStatus === 'success') this.loading = false
      if (this.loadingStatus === 'error') {
        this.removeTagDestination(this.lastValueIndexSearch) 
        this.loading = false
      }
    },
    options() {
      if (this.selectDefault) {
        this.valuesFilterSelect = this.selectDefault
      }
    }
  },
  methods : {
    change (optionSel) {
      if (this.showLoading) this.loading = true
      const index = this.valueTags.findIndex(ele => ele.group === optionSel.group)
      if (this.valueTags[index]?.group === optionSel.group) {
        this.valueTags[index] = optionSel
      }
      else {
        this.valueTags.push(optionSel)
      }
      //Toma el index del ultimo elemento agregado para que en caso de error al hacer la peticion se deseleccion y se elimine el tag
      this.lastValueIndexSearch = this.valueTags.findIndex(ele => ele.group === optionSel.group)
      this.tagsRender++
      this.onChange(this.valueTags)
    },
    generateSelectArr () {
      this.valuesFilterSelect = new Array(this.options.length).fill(null)
    },
    removeTagDestination (index) {
      const indexOpt = this.valuesFilterSelect.findIndex(ele => ele?.group === this.valueTags[index].group)
      this.valueTags.splice(index, 1)
      this.valuesFilterSelect[indexOpt] = null
      this.onChange(this.valueTags)
    },
    clearAll () {
      this.valueTags = []
      this.onChange([])
      this.generateSelectArr()
    }
  }
}
</script>
<style lang="scss">

.btn-outline-gray {
  .btn-outline-secondary {
    border: 1px solid #D8D6DE !important;
    color: #9A9A9A !important;
  } 
}
#dropdown-filter-input {
  padding-right: 5px;
}
.filterInput-menu-class {
  border: 1px solid #1B4284 !important;
  white-space: nowrap;
  .filterInput-overlay {
    .b-overlay div:first-child {
      background-color: #ffffff !important;
    }
  }
  legend {
    color: #1B4284;
    font-weight: bold;
  }
}
ul.dropdown-menu.filterInput-menu-class{
  width: fit-content !important;
}
.filterInput-dropdown-divider {
  background: #c6d0e0;
}
.badge-cnt-filter {
  padding: 7px !important;
  border-radius: 1rem !important;
}
.filterInput-XIcon {
  cursor: pointer;
}
.btn-cnt-filter--icon {
  > button{
    padding: 0.5rem;
    > span.spinner-border{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}
</style>